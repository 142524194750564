// src/App.jsx
import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import LoginPage from './pages/LoginPage';
import Setup2FAPage from './pages/Setup2FAPage';
import DashboardLayout from './components/layout/DashboardLayout';
import ProtectedRoute from './components/ProtectedRoute';
import LogsPage from './pages/dashboard/logs/LogsPage';
import { Toaster } from 'react-hot-toast';

// Dashboard
import Dashboard from './pages/dashboard/Dashboard';

// Warehouse
import WarehouseList from './pages/dashboard/warehouse/WarehouseList';
import WarehouseAdd from './pages/dashboard/warehouse/WarehouseAdd';
import WarehouseEdit from './pages/dashboard/warehouse/WarehouseEdit';

// Product
import ProductList from './pages/dashboard/product/ProductList';
import ProductAdd from './pages/dashboard/product/ProductAdd';
import ProductEdit from './pages/dashboard/product/ProductEdit';

// Order
import OrderList from './pages/dashboard/order/OrderList';
import OrderCreate from './pages/dashboard/order/OrderCreate';

// Reports
import MarketplaceStats from './pages/dashboard/reports/MarketplaceStats';
import ProductStats from './pages/dashboard/reports/ProductStats';

//E-Commerce Records
import CreateRecord from './pages/dashboard/e-commerce-records/CreateRecord';
import ListRecords from './pages/dashboard/e-commerce-records/ListRecords';

function App() {
  return (
    <AuthProvider>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/setup-2fa" element={<Setup2FAPage />} />
        
        {/* Dashboard */}
        <Route path="/dashboard" element={
          <ProtectedRoute permission="admin">
            <DashboardLayout>
              <Dashboard />
            </DashboardLayout>
          </ProtectedRoute>
        } />

        {/* Warehouse Routes */}
        <Route path="/dashboard/warehouse/*" element={
          <ProtectedRoute permission="admin">
            <DashboardLayout>
              <Routes>
                <Route path="list" element={<WarehouseList />} />
                <Route path="add" element={<WarehouseAdd />} />
                <Route path="edit/:id" element={<WarehouseEdit />} />
              </Routes>
            </DashboardLayout>
          </ProtectedRoute>
        } />

        {/* Product Routes */}
        <Route path="/dashboard/product/*" element={
          <ProtectedRoute permission="admin">
            <DashboardLayout>
              <Routes>
                <Route path="list" element={<ProductList />} />
                <Route path="add" element={<ProductAdd />} />
                <Route path="edit/:id" element={<ProductEdit />} />
              </Routes>
            </DashboardLayout>
          </ProtectedRoute>
        } />

        {/* Order Routes */}
        <Route path="/dashboard/order/*" element={
          <ProtectedRoute permission="admin">
            <DashboardLayout>
              <Routes>
                <Route path="list" element={<OrderList />} />
                <Route path="create" element={<OrderCreate />} />
              </Routes>
            </DashboardLayout>
          </ProtectedRoute>
        } />

        {/* Reports Routes */}
        <Route path="/dashboard/reports/*" element={
          <ProtectedRoute permission="admin">
            <DashboardLayout>
              <Routes>
                <Route path="marketplace" element={<MarketplaceStats />} />
                <Route path="products" element={<ProductStats />} />
              </Routes>
            </DashboardLayout>
          </ProtectedRoute>
        } />

        {/* Logs Route */}
        <Route path="/dashboard/logs" element={
          <ProtectedRoute permission="admin">
            <DashboardLayout>
              <LogsPage />
            </DashboardLayout>
          </ProtectedRoute>
        } />

         {/* E-Commerce Route */}
        <Route path="/dashboard/e-commerce-records/*" element={
          <ProtectedRoute>
            <DashboardLayout>
              <Routes>
                <Route path="create" element={<CreateRecord />} />
                <Route path="list" element={<ListRecords />} />
              </Routes>
            </DashboardLayout>
          </ProtectedRoute>
        } />

        {/* Ana sayfaya yönlendirme */}
        <Route path="/" element={
          <ProtectedRoute>
            {({ user }) => (
              <Navigate 
                to={user?.role === 'admin' ? '/dashboard' : '/dashboard/e-commerce-records/list'} 
                replace 
              />
            )}
          </ProtectedRoute>
        } />
      </Routes>
      <Toaster 
        position="top-right"
        toastOptions={{
          duration: 3000,
          style: {
            background: '#fff',
            color: '#333',
          },
          success: {
            duration: 3000,
            // Başarılı bildirimler için stil
          },
          error: {
            duration: 4000,
            // Hata bildirimleri için stil
          },
        }}
      />
    </AuthProvider>
  );
}

export default App;