import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { Button } from '../components/ui/button';
import { Input } from '../components/ui/input';
import { Lock, User, Shield } from 'lucide-react';

const LoginPage = () => {
  const navigate = useNavigate();
  const { login, verify2FA } = useAuth();
  const [step, setStep] = useState('credentials'); // 'credentials' or '2fa'
  const [formData, setFormData] = useState({
    username: '',
    password: '',
    otpCode: ''
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');

    const result = await login({
      username: formData.username,
      password: formData.password,
    });

    if (result.success) {
      if (result.require2FA) {
        setStep('2fa');
      }
      // 2FA kurulumu gerekiyorsa Setup2FAPage'e yönlendirilecek
      // Bu kısım AuthContext içinde halihazırda yapılıyor
    } else {
      setError(result.error);
    }
    
    setIsLoading(false);
  };
  
  const handle2FASubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');

    const result = await verify2FA(formData.otpCode);

    if (!result.success) {
      setError(result.error);
    }
    // Başarılı durumda AuthContext içinde yönlendirme yapılıyor

    setIsLoading(false);
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50">
      <div className="max-w-md w-full p-6 bg-white rounded-lg shadow-lg">
        <div className="text-center mb-8">
          <h2 className="text-2xl font-bold text-gray-900">
            Loop BO Admin Panel
          </h2>
        </div>

        {step === 'credentials' ? (
          <form onSubmit={handleLogin} className="space-y-6">
            <div className="space-y-4">
              <div className="relative">
                <User className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
                <Input
                  type="text"
                  name="username"
                  placeholder="Kullanıcı Adı"
                  className="pl-10"
                  value={formData.username}
                  onChange={handleInputChange}
                  required
                />
              </div>

              <div className="relative">
                <Lock className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
                <Input
                  type="password"
                  name="password"
                  placeholder="Şifre"
                  className="pl-10"
                  value={formData.password}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>

            {error && (
              <div className="text-sm text-red-500 mt-2">
                {error}
              </div>
            )}

            <Button
              type="submit"
              className="w-full"
              disabled={isLoading}
            >
              {isLoading ? "Giriş yapılıyor..." : "Giriş Yap"}
            </Button>
          </form>
        ) : (
          <form onSubmit={handle2FASubmit} className="space-y-6">
            <div className="relative">
              <Shield className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
              <Input
                type="text"
                name="otpCode"
                placeholder="Doğrulama Kodu"
                className="pl-10"
                value={formData.otpCode}
                onChange={handleInputChange}
                maxLength={6}
                required
              />
            </div>

            {error && (
              <div className="text-sm text-red-500 mt-2">
                {error}
              </div>
            )}

            <Button
              type="submit"
              className="w-full"
              disabled={isLoading}
            >
              {isLoading ? "Doğrulanıyor..." : "Doğrula"}
            </Button>

            <Button
              type="button"
              variant="outline"
              className="w-full mt-2"
              onClick={() => {
                setStep('credentials');
                setError('');
                setFormData(prev => ({ ...prev, otpCode: '' }));
              }}
            >
              Geri Dön
            </Button>
          </form>
        )}
      </div>
    </div>
  );
};

export default LoginPage;