// src/pages/dashboard/warehouse/WarehouseAdd.jsx
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../components/ui/button';
import { Input } from '../../../components/ui/input';
import { Card, CardHeader, CardTitle, CardDescription, CardContent, CardFooter } from '../../../components/ui/card';
import { toast } from 'react-hot-toast';

const WarehouseAdd = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    code: ''
  });
  const [isLoading, setIsLoading] = useState(false);

  const API_URL = process.env.REACT_APP_API_URL;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await fetch(`${API_URL}/api/warehouse`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify(formData)
      });

      const data = await response.json();

      if (response.ok) {
        const successMessage = 'Depo başarıyla eklendi';
        toast.success(successMessage);
        window.dispatchEvent(new CustomEvent('toast', { 
          detail: { message: successMessage, type: 'success' }
        }));
        navigate('/dashboard/warehouse/list');
      } else {
        throw new Error(data.error);
      }
    } catch (error) {
      const errorMessage = error.message || 'Bir hata oluştu';
      toast.error(errorMessage);
      window.dispatchEvent(new CustomEvent('toast', { 
        detail: { message: errorMessage, type: 'error' }
      }));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="max-w-2xl mx-auto">
      <Card>
        <CardHeader>
          <CardTitle>Yeni Depo Ekle</CardTitle>
          <CardDescription>Depo bilgilerini girerek yeni bir depo oluşturun.</CardDescription>
        </CardHeader>
        
        <form onSubmit={handleSubmit}>
          <CardContent className="space-y-4">
            <div className="space-y-1">
              <label className="text-sm font-medium">Depo Adı</label>
              <Input
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                required
                placeholder="Depo adını girin"
              />
            </div>

            <div className="space-y-1">
              <label className="text-sm font-medium">Depo Kodu</label>
              <Input
                name="code"
                value={formData.code}
                onChange={handleInputChange}
                required
                placeholder="Depo kodunu girin"
                className="uppercase"
                maxLength={10}
              />
            </div>
          </CardContent>

          <CardFooter className="flex justify-end space-x-4">
            <Button
              type="button"
              variant="outline"
              onClick={() => navigate('/dashboard/warehouse/list')}
            >
              İptal
            </Button>
            <Button
              type="submit"
              disabled={isLoading}
            >
              {isLoading ? 'Ekleniyor...' : 'Depo Ekle'}
            </Button>
          </CardFooter>
        </form>
      </Card>
    </div>
  );
};

export default WarehouseAdd;