// src/components/ui/DatePicker.jsx
import React from 'react';
import { Input } from './input';

const DatePicker = ({ value, onChange, className = "" }) => {
    // Istanbul timezone'una göre bugünün tarihi
    const getToday = () => {
        const now = new Date();
        const istanbulOffset = 3; // UTC+3
        const utc = now.getTime() + (now.getTimezoneOffset() * 60000);
        return new Date(utc + (3600000 * istanbulOffset));
    };

    const formatDate = (date) => {
        if (!date) return '';
        const d = new Date(date);
        const day = String(d.getDate()).padStart(2, '0');
        const month = String(d.getMonth() + 1).padStart(2, '0');
        const year = d.getFullYear();
        return `${day}/${month}/${year}`;
    };

    const handleChange = (e) => {
        const date = new Date(e.target.value);
        onChange(formatDate(date));
    };

    // Convert DD/MM/YYYY to YYYY-MM-DD for input value
    const getInputValue = () => {
        if (!value) {
            const today = getToday();
            return today.toISOString().split('T')[0];
        }
        const [day, month, year] = value.split('/');
        return `${year}-${month}-${day}`;
    };

    return (
        <Input
            type="date"
            value={getInputValue()}
            onChange={handleChange}
            className={`w-40 ${className}`}
        />
    );
};

export default DatePicker;