// src/components/layout/Sidebar.jsx
import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import { cn } from '../../lib/utils';
import { ReactComponent as LoopLogo } from '../../looplogo.svg';
import {
  LayoutDashboard,
  Warehouse,
  Package,
  ClipboardList,
  Terminal,
  Settings,
  LogOut,
  ChevronDown,
  BarChart,
  Plus,
  List,
  LineChart,
  PieChart,
  ShoppingBag,
  X
} from 'lucide-react';

const MenuItem = ({ item, isActive, onClick, isOpen, onMobileClose }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const hasSubmenu = item.submenu && item.submenu.length > 0;

  const handleClick = () => {
    onClick();
    if (item.path) {
      navigate(item.path);
      if (window.innerWidth < 1024) {
        onMobileClose?.();
      }
    }
  };

  return (
    <div>
      <div
        onClick={handleClick}
        className={cn(
          "flex items-center justify-between px-3 py-2 text-sm font-medium rounded-md cursor-pointer",
          isActive
            ? "bg-gray-100 text-gray-900"
            : "text-gray-600 hover:bg-gray-50 hover:text-gray-900"
        )}
      >
        <div className="flex items-center">
          <item.icon className="w-5 h-5 mr-3" />
          {item.title}
        </div>
        {hasSubmenu && (
          <ChevronDown className={cn(
            "w-4 h-4 transition-transform",
            isOpen ? "transform rotate-180" : ""
          )} />
        )}
      </div>

      {hasSubmenu && isOpen && (
        <div className="ml-6 mt-1 space-y-1">
          {item.submenu.map((subItem) => (
            <Link
              key={subItem.path}
              to={subItem.path}
              onClick={() => {
                if (window.innerWidth < 1024) {
                  onMobileClose?.();
                }
              }}
              className={cn(
                "flex items-center px-3 py-2 text-sm font-medium rounded-md",
                location.pathname === subItem.path
                  ? "bg-gray-100 text-gray-900"
                  : "text-gray-600 hover:bg-gray-50 hover:text-gray-900"
              )}
            >
              <subItem.icon className="w-4 h-4 mr-3" />
              {subItem.title}
            </Link>
          ))}
        </div>
      )}
    </div>
  );
};

const Sidebar = ({ isOpen, onClose }) => {
  const location = useLocation();
  const { user, logout } = useAuth();
  const [openMenus, setOpenMenus] = useState({});

  const sidebarItems = [
    {
      title: 'Dashboard',
      icon: LayoutDashboard,
      path: '/dashboard',
      permission: 'admin'
    },
    {
      title: 'Depolar',
      icon: Warehouse,
      permission: 'admin',
      submenu: [
        {
          title: 'Depo Listesi',
          icon: List,
          path: '/dashboard/warehouse/list'
        },
        {
          title: 'Depo Ekle',
          icon: Plus,
          path: '/dashboard/warehouse/add'
        }
      ]
    },
    {
      title: 'Ürünler',
      icon: Package,
      permission: 'admin',
      submenu: [
        {
          title: 'Ürün Listesi',
          icon: List,
          path: '/dashboard/product/list'
        },
        {
          title: 'Ürün Ekle',
          icon: Plus,
          path: '/dashboard/product/add'
        }
      ]
    },
    {
      title: 'Siparişler',
      icon: ClipboardList,
      permission: 'admin',
      submenu: [
        {
          title: 'Sipariş Listesi',
          icon: List,
          path: '/dashboard/order/list'
        },
        {
          title: 'Sipariş Oluştur',
          icon: Plus,
          path: '/dashboard/order/create'
        }
      ]
    },
    {
      title: 'Raporlar',
      icon: LineChart,
      permission: 'admin',
      submenu: [
        {
          title: 'Marketplace İstatistikleri',
          icon: PieChart,
          path: '/dashboard/reports/marketplace'
        },
        {
          title: 'Ürün İstatistikleri',
          icon: BarChart,
          path: '/dashboard/reports/products'
        }
      ]
    },
    {
      title: 'Sistem',
      icon: Settings,
      permission: 'admin',
      submenu: [
        {
          title: 'Loglar',
          icon: Terminal,
          path: '/dashboard/logs'
        },
        {
          title: 'Ayarlar',
          icon: Settings,
          path: '/dashboard/settings'
        }
      ]
    },
    {
      title: 'E-Ticaret Kayıtları',
      icon: ShoppingBag,
      permission: null,
      submenu: [
        {
          title: 'Kayıt Oluştur',
          icon: Plus,
          path: '/dashboard/e-commerce-records/create'
        },
        {
          title: 'Kayıtları Listele',
          icon: List,
          path: '/dashboard/e-commerce-records/list'
        }
      ]
    }
  ];

  const isActiveRoute = (path) => {
    if (!path) return false;
    return location.pathname.startsWith(path);
  };

  const toggleMenu = (title) => {
    setOpenMenus(prev => ({
      ...prev,
      [title]: !prev[title]
    }));
  };

  const hasPermission = (requiredPermission) => {
    if (!requiredPermission) return true;
    const userRole = user?.role || 'admin';
    return userRole === requiredPermission;
  };

  const filteredItems = sidebarItems.filter(item => 
    hasPermission(item.permission)
  );

  return (
    <>
      {/* Overlay - Sadece mobilde ve menü açıkken göster */}
      {isOpen && (
        <div
          className="fixed inset-0 bg-black/50 lg:hidden z-30"
          onClick={onClose}
        />
      )}

      {/* Sidebar */}
      <div className={cn(
        "fixed inset-y-0 left-0 z-40 w-64 bg-white border-r transform transition-transform duration-200 ease-in-out lg:translate-x-0",
        isOpen ? "translate-x-0" : "-translate-x-full"
      )}>
        {/* Close Button - Sadece mobilde göster */}
        <button
          onClick={onClose}
          className="lg:hidden absolute right-4 top-4 p-1 hover:bg-gray-100 rounded-md"
        >
          <X className="w-5 h-5" />
        </button>

        {/* Logo */}
        <div className="p-6">
          <Link 
            to={user?.role === 'admin' ? '/dashboard' : '/dashboard/e-commerce-records/list'}
            className="flex items-center space-x-2" 
            onClick={() => {
              if (window.innerWidth < 1024) {
                onClose?.();
              }
            }}
          >
            <LoopLogo className="h-12 w-auto" />
          </Link>
        </div>

        {/* Navigation */}
        <nav className="flex-1 px-3 space-y-1 overflow-y-auto">
          {filteredItems.map((item) => (
            <MenuItem
              key={item.title}
              item={item}
              isActive={isActiveRoute(item.path)}
              isOpen={openMenus[item.title]}
              onMobileClose={onClose}
              onClick={() => {
                toggleMenu(item.title);
              }}
            />
          ))}
        </nav>

        {/* Logout Button */}
        <div className="p-4 border-t">
          <button
            onClick={() => {
              logout();
              if (window.innerWidth < 1024) {
                onClose?.();
              }
            }}
            className="flex items-center w-full px-3 py-2 text-sm font-medium text-gray-600 rounded-md hover:bg-gray-50 hover:text-gray-900"
          >
            <LogOut className="w-5 h-5 mr-3" />
            Çıkış Yap
          </button>
        </div>
      </div>
    </>
  );
};

export default Sidebar;