import React, { useState } from 'react';
import Sidebar from './Sidebar';
import Header from './Header';
import { Card, CardContent } from '../ui/card';

const DashboardLayout = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  return (
    <div className="min-h-screen flex">
      {/* Desktop Sidebar */}
      <div className="hidden lg:block lg:w-64 fixed inset-y-0">
        <Sidebar 
          isOpen={true} 
          onClose={() => {}}
        />
      </div>

      {/* Main Content */}
      <div className="flex-1 lg:ml-64 w-full">
        {/* Header */}
        <div className="fixed top-0 right-0 left-0 lg:left-64 bg-white z-10 border-b h-16">
          <Header onMenuClick={() => setIsSidebarOpen(true)} />
        </div>
        
        {/* Page Content */}
        <main className="mt-16 p-4 sm:p-6">
          <Card className="min-h-[calc(100vh-5rem)]">
            <CardContent className="p-4 sm:p-6">
              {children}
            </CardContent>
          </Card>
        </main>
      </div>

      {/* Mobile Sidebar */}
      <div className="lg:hidden">
        <Sidebar 
          isOpen={isSidebarOpen} 
          onClose={() => setIsSidebarOpen(false)} 
        />
      </div>
    </div>
  );
};

export default DashboardLayout;