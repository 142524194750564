import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../components/ui/button';
import { Input } from '../components/ui/input';
import { Shield } from 'lucide-react';

const Setup2FAPage = () => {
  const navigate = useNavigate();
  const [qrCode, setQrCode] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const API_URL = process.env.REACT_APP_API_URL;

  const generateQRCode = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${API_URL}/api/auth/setup-2fa`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('setupToken')}`,
          'Content-Type': 'application/json'
        }
      });

      const data = await response.json();
      if (response.ok) {
        setQrCode(data.qrCode);
      } else {
        setError(data.error || 'QR kod oluşturulurken bir hata oluştu');
      }
    } catch (err) {
      setError('Sunucu bağlantısında hata oluştu');
    } finally {
      setIsLoading(false);
    }
  };

  const verifySetup = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await fetch(`${API_URL}/api/auth/verify-2fa`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('setupToken')}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ token: verificationCode })
      });

      const data = await response.json();
      if (response.ok) {
        localStorage.setItem('token', data.token);
        localStorage.removeItem('setupToken');
        navigate('/dashboard');
      } else {
        setError(data.error || 'Doğrulama başarısız');
      }
    } catch (err) {
      setError('Sunucu bağlantısında hata oluştu');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50">
      <div className="max-w-md w-full p-6 bg-white rounded-lg shadow-lg">
        <div className="text-center mb-8">
          <h2 className="text-2xl font-bold text-gray-900">
            İki Faktörlü Kimlik Doğrulama Kurulumu
          </h2>
        </div>

        <div className="space-y-6">
          {!qrCode ? (
            <div className="space-y-4">
              <p className="text-gray-600 text-center">
                Google Authenticator uygulamasını telefonunuza indirin ve QR kodu taratmak için hazır olun.
              </p>
              <Button
                onClick={generateQRCode}
                className="w-full"
                disabled={isLoading}
              >
                {isLoading ? "QR Kod Oluşturuluyor..." : "QR Kod Oluştur"}
              </Button>
            </div>
          ) : (
            <form onSubmit={verifySetup} className="space-y-6">
              <div className="flex justify-center mb-6">
                <img src={qrCode} alt="2FA QR Code" className="max-w-full" />
              </div>

              <p className="text-sm text-gray-600 text-center mb-4">
                QR kodu Google Authenticator uygulamasında tarayın ve oluşturulan 6 haneli kodu girin.
              </p>

              <div className="relative">
                <Shield className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
                <Input
                  type="text"
                  placeholder="Doğrulama Kodu"
                  className="pl-10"
                  value={verificationCode}
                  onChange={(e) => setVerificationCode(e.target.value)}
                  maxLength={6}
                  required
                />
              </div>

              {error && (
                <div className="text-sm text-red-500 mt-2">
                  {error}
                </div>
              )}

              <Button
                type="submit"
                className="w-full"
                disabled={isLoading}
              >
                {isLoading ? "Doğrulanıyor..." : "Kurulumu Tamamla"}
              </Button>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default Setup2FAPage;