import React, { useState, useEffect } from 'react';
import { Bell, User, X, Menu } from 'lucide-react';
import { Button } from '../ui/button';
import { useAuth } from '../../hooks/useAuth';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../ui/dropdown-menu";
import { ScrollArea } from "../ui/scroll-area";

const API_URL = process.env.REACT_APP_API_URL;

const Header = ({ onMenuClick }) => {
  const { user } = useAuth();
  const [notifications, setNotifications] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);

  const addNotification = (message, type = 'info') => {
    const newNotification = {
      _id: Date.now(),
      message,
      type,
      read: false,
      createdAt: new Date()
    };

    setNotifications(prev => [newNotification, ...prev]);
    setUnreadCount(prev => prev + 1);
  };

  useEffect(() => {
    const handleToast = (event) => {
      console.log('Toast event received:', event.detail);
      addNotification(event.detail.message, event.detail.type);
    };

    window.addEventListener('toast', handleToast);

    return () => {
      window.removeEventListener('toast', handleToast);
    };
  }, []);

  useEffect(() => {
    fetchNotifications();
  }, []);

  const fetchNotifications = async () => {
    try {
      const response = await fetch(`${API_URL}/api/notifications`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      
      if (!response.ok) throw new Error('Bildirimler alınamadı');
      
      const data = await response.json();
      setNotifications(data);
      setUnreadCount(data.filter(n => !n.read).length);
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  };

  const markAsRead = async (id) => {
    try {
      await fetch(`${API_URL}/api/notifications/${id}/read`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      
      setNotifications(notifications.map(n => 
        n._id === id ? { ...n, read: true } : n
      ));
      setUnreadCount(prev => Math.max(0, prev - 1));
    } catch (error) {
      console.error('Error marking notification as read:', error);
    }
  };

  return (
    <header className="h-16 bg-white shadow-sm fixed top-0 right-0 left-0 lg:left-64 z-10">
      <div className="h-full px-4 lg:px-6 flex items-center justify-between">
        <div className="flex items-center">
          {/* Hamburger Menu - Sadece mobilde görünür */}
          <Button 
            variant="ghost" 
            size="icon" 
            className="lg:hidden mr-2"
            onClick={onMenuClick}
          >
            <Menu className="h-6 w-6" />
          </Button>

          <h2 className="text-lg lg:text-xl font-semibold text-gray-700">
            {user?.role === 'admin' ? 'Dashboard' : 'E-Ticaret Kayıtları'}
          </h2>
        </div>
        
        <div className="flex items-center space-x-2 lg:space-x-4">
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button 
                variant="ghost" 
                size="icon"
                className="text-gray-600 relative"
              >
                <Bell className="h-5 w-5" />
                {unreadCount > 0 && (
                  <span className="absolute -top-1 -right-1 bg-red-500 text-white text-xs rounded-full w-4 h-4 flex items-center justify-center">
                    {unreadCount}
                  </span>
                )}
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end" className="w-72 lg:w-80">
              <div className="flex items-center justify-between px-4 py-2 border-b">
                <span className="font-medium">Bildirimler</span>
                {notifications.length > 0 && (
                  <Button 
                    variant="ghost" 
                    size="sm" 
                    onClick={() => setNotifications([])}
                    className="text-xs"
                  >
                    Tümünü Temizle
                  </Button>
                )}
              </div>
              <ScrollArea className="max-h-[300px]">
                {notifications.length > 0 ? (
                  notifications.map((notification) => (
                    <DropdownMenuItem
                      key={notification._id}
                      className={`flex items-start p-3 space-x-3 cursor-default ${
                        !notification.read ? 'bg-blue-50' : ''
                      }`}
                    >
                      <div className="flex-1">
                        <p className="text-sm">{notification.message}</p>
                        <p className="text-xs text-gray-500 mt-1">
                          {new Date(notification.createdAt).toLocaleString()}
                        </p>
                      </div>
                      {!notification.read && (
                        <Button
                          variant="ghost"
                          size="icon"
                          className="h-6 w-6"
                          onClick={() => markAsRead(notification._id)}
                        >
                          <X className="h-4 w-4" />
                        </Button>
                      )}
                    </DropdownMenuItem>
                  ))
                ) : (
                  <div className="py-8 text-center text-gray-500">
                    Bildirim bulunmuyor
                  </div>
                )}
              </ScrollArea>
            </DropdownMenuContent>
          </DropdownMenu>
          
          <div className="flex items-center space-x-2">
            <div className="w-8 h-8 bg-blue-500 rounded-full flex items-center justify-center">
              <User className="h-5 w-5 text-white" />
            </div>
            <span className="hidden sm:inline-block text-sm font-medium">{user?.username}</span>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;