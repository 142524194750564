// src/components/ui/ComboBox.jsx
import React, { useState, useEffect, useRef } from 'react';
import { Input } from './input';
import { Button } from './button';
import { ChevronDown, Search } from 'lucide-react';

const ComboBox = ({ 
    placeholder, 
    onSearch, 
    onSelect, 
    value, 
    displayKey = 'name',
    valueKey = '_id',
    items = [],
    disabled = false
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [searchResults, setSearchResults] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [showSearchResults, setShowSearchResults] = useState(false);
    const wrapperRef = useRef(null);

    // Value değiştiğinde searchText'i güncelle
    useEffect(() => {
        if (value) {
            setSearchText(value[displayKey] || '');
        } else {
            setSearchText('');
        }
    }, [value, displayKey]);

    useEffect(() => {
        function handleClickOutside(event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setIsOpen(false);
                setShowSearchResults(false);
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    const handleSearch = async (text) => {
        setSearchText(text);
        if (text.length >= 2) {
            const results = await onSearch(text);
            setSearchResults(Array.isArray(results) ? results : []); // Array kontrolü eklendi
            setShowSearchResults(true);
        } else {
            setSearchResults([]);
            setShowSearchResults(false);
        }
    };

    const handleSelect = (option) => {
        if (option) {  // Option kontrolü eklendi
            onSelect(option);
            setSearchText(option[displayKey] || '');
            setIsOpen(false);
            setShowSearchResults(false);
        }
    };

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
        setShowSearchResults(false);
    };

    return (
        <div className="relative" ref={wrapperRef}>
            <div className="relative flex">
                <Input
                    type="text"
                    placeholder={`${placeholder} için arama yapın...`}
                    value={searchText}
                    onChange={(e) => handleSearch(e.target.value)}
                    onFocus={() => searchText.length >= 2 && setShowSearchResults(true)}
                    disabled={disabled}
                    className="rounded-r-none"
                />
                <Button
                    type="button"
                    variant="outline"
                    className="rounded-l-none border-l-0"
                    onClick={toggleDropdown}
                >
                    <ChevronDown className="h-4 w-4" />
                </Button>
            </div>

            {(isOpen || showSearchResults) && (
                <div className="absolute z-10 w-full mt-1 bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-md shadow-lg max-h-60 overflow-auto">
                    {showSearchResults ? (
                        searchResults.map((option) => (
                            <div
                                key={option[valueKey]}
                                className="px-4 py-2 cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-700 flex items-center"
                                onClick={() => handleSelect(option)}
                            >
                                <Search className="h-4 w-4 mr-2 text-gray-400" />
                                {option[displayKey]}
                            </div>
                        ))
                    ) : (
                        (Array.isArray(items) ? items : []).map((option) => (
                            <div
                                key={option[valueKey]}
                                className="px-4 py-2 cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-700"
                                onClick={() => handleSelect(option)}
                            >
                                {option[displayKey]}
                            </div>
                        ))
                    )}
                </div>
            )}
        </div>
    );
};

export default ComboBox;