import React, { useState, useRef } from 'react';
import { Button } from '../../../components/ui/button';
import { Input } from '../../../components/ui/input';
import { Calendar } from '../../../components/ui/calendar';
import { Popover, PopoverContent, PopoverTrigger } from '../../../components/ui/popover';
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from '../../../components/ui/card';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../../../components/ui/select';
import { format } from "date-fns";
import { tr } from 'date-fns/locale';
import { cn } from '../../../lib/utils';
import { Camera, CalendarIcon, Store } from 'lucide-react';
import toast from 'react-hot-toast';

export default function CreateRecord() {
  const [platform, setPlatform] = useState('');
  const [orderNumber, setOrderNumber] = useState('');
  const [date, setDate] = useState(new Date());
  const [image, setImage] = useState(null);
  const [openCalendar, setOpenCalendar] = useState(false);
  const fileInputRef = useRef(null);

  const platforms = [
    { label: 'Loop', value: 'Loop' },
    { label: 'Trendyol', value: 'Trendyol' },
    { label: 'Hepsiburada', value: 'Hepsiburada' },
    { label: 'N11', value: 'N11' },
    { label: 'Amazon', value: 'Amazon' },
  ];

  const compressImage = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target.result;
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const MAX_WIDTH = 800;
          const MAX_HEIGHT = 800;
          let width = img.width;
          let height = img.height;

          if (width > height) {
            if (width > MAX_WIDTH) {
              height *= MAX_WIDTH / width;
              width = MAX_WIDTH;
            }
          } else {
            if (height > MAX_HEIGHT) {
              width *= MAX_HEIGHT / height;
              height = MAX_HEIGHT;
            }
          }

          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, width, height);
          canvas.toBlob(resolve, 'image/jpeg', 0.7);
        };
      };
    });
  };

  const handleCapture = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const compressedBlob = await compressImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(compressedBlob);
    }
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!platform) {
      toast.error('Lütfen platform seçiniz');
      return;
    }

    if (!orderNumber) {
      toast.error('Lütfen sipariş numarası giriniz');
      return;
    }

    if (!image) {
      toast.error('Lütfen bir fotoğraf ekleyiniz');
      return;
    }

    // Base64'ten Blob'a dönüştürme
    const imageBlob = await (async () => {
      if (image && image.startsWith('data:image')) {
        const base64Response = await fetch(image);
        return await base64Response.blob();
      }
      return null;
    })();

    const formData = new FormData();
    formData.append('platform', platform);
    formData.append('orderNumber', orderNumber);
    const turkeyDate = new Date(date).toLocaleString('en-US', { timeZone: 'Europe/Istanbul' });
    formData.append('date', new Date(turkeyDate).toISOString());

    if (imageBlob) {
      formData.append('image', imageBlob, 'photo.jpg');
    }

    try {
      const loadingToast = toast.loading('Kayıt oluşturuluyor...');

      const response = await fetch(`/api/ecommerce-records`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        body: formData
      });
      
      if (response.ok) {
        toast.success('Kayıt başarıyla oluşturuldu', {
          id: loadingToast,
        });
        // Form alanlarını temizle
        setPlatform('');
        setOrderNumber('');
        setImage(null);
        setDate(new Date());
      } else {
        toast.error('Kayıt oluşturulurken bir hata oluştu', {
          id: loadingToast,
        });
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('Bir hata oluştu: ' + error.message);
    }
  };

    // Türkçe tarih formatı için
    const months = {
      1: 'Ocak',
      2: 'Şubat',
      3: 'Mart',
      4: 'Nisan',
      5: 'Mayıs',
      6: 'Haziran',
      7: 'Temmuz',
      8: 'Ağustos',
      9: 'Eylül',
      10: 'Ekim',
      11: 'Kasım',
      12: 'Aralık'
    };
  
    const weekDays = {
      monday: "Pt",
      tuesday: "Sa",
      wednesday: "Ça",
      thursday: "Pe",
      friday: "Cu",
      saturday: "Ct",
      sunday: "Pz"
    };
  
  return (
    <Card className="min-h-screen">
      <CardHeader>
        <CardTitle>Yeni Kayıt</CardTitle>
        <CardDescription>Sipariş bilgilerini girin</CardDescription>
      </CardHeader>
      <CardContent>
        <form onSubmit={handleSubmit} className="space-y-4">
          {/* Platform Seçimi */}
          <div className="space-y-1.5">
            <label className="text-sm font-medium">Platform</label>
            <Select value={platform} onValueChange={setPlatform}>
              <SelectTrigger>
                <SelectValue placeholder="Platform seçiniz" />
              </SelectTrigger>
              <SelectContent>
                {platforms.map((p) => (
                  <SelectItem key={p.value} value={p.value}>{p.label}</SelectItem>
                ))}
              </SelectContent>
            </Select>
        </div>

          {/* Sipariş Numarası */}
          <div className="space-y-1.5">
            <label className="text-sm font-medium">Sipariş Numarası</label>
            <Input
              placeholder="Sipariş numarası"
              value={orderNumber}
              onChange={(e) => setOrderNumber(e.target.value)}
            />
          </div>

          {/* Tarih Seçici - Güncellenen kısım */}
          <div className="space-y-1.5">
            <label className="text-sm font-medium">Sipariş Tarihi</label>
            <Popover open={openCalendar} onOpenChange={setOpenCalendar}>
              <PopoverTrigger asChild>
                <Button variant="outline" className="w-full justify-start">
                  <CalendarIcon className="mr-2 h-4 w-4" />
                  {date ? format(date, "d MMMM yyyy", { locale: tr }) : "Tarih seçiniz"}
                </Button>
              </PopoverTrigger>
              <PopoverContent 
                align="start" 
                className="w-auto p-0 bg-white z-50 relative border shadow-lg rounded-md"
              >
                <div className="bg-white rounded-md">
                  <Calendar
                    mode="single"
                    selected={date}
                    onSelect={(newDate) => {
                      setDate(newDate);
                      setOpenCalendar(false);
                    }}
                    locale={tr}
                    weekStartsOn={1}
                    className="bg-white"
                    classNames={{
                      month: "text-center font-semibold",
                      day_range_end: "day-range-end",
                      day_selected: "bg-primary text-primary-foreground hover:bg-primary hover:text-primary-foreground focus:bg-primary focus:text-primary-foreground",
                      head_cell: "text-muted-foreground font-normal w-9",
                      cell: "text-center text-sm p-0 relative [&:has([aria-selected])]:bg-accent first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20 h-9 w-9",
                      nav_button_previous: "absolute left-1",
                      nav_button_next: "absolute right-1",
                      caption: "relative flex justify-center pt-1 pb-4"
                    }}
                    formatters={{
                      formatCaption: (date, options) => {
                        return `${months[date.getMonth() + 1]} ${date.getFullYear()}`;
                      },
                      formatWeekdayName: (date) => {
                        return weekDays[format(date, 'EEEE', { locale: tr }).toLowerCase()];
                      }
                    }}
                  />
                </div>
              </PopoverContent>
            </Popover>
          </div>

          {/* Fotoğraf Alanı */}
          <div className="space-y-1.5">
            <label className="text-sm font-medium">Fotoğraf</label>
            <input
              type="file"
              accept="image/*"
              capture="environment"
              onChange={handleCapture}
              ref={fileInputRef}
              className="hidden"
            />
            {image ? (
              <div className="space-y-2">
                <img 
                  src={image} 
                  alt="Captured" 
                  className="w-full h-48 object-cover rounded-lg border-2 border-gray-200"
                />
                <Button
                  type="button"
                  onClick={() => fileInputRef.current?.click()}
                  variant="destructive"
                  className="w-full text-base py-6"
                >
                  <Camera className="mr-2 h-5 w-5" />
                  Fotoğrafı Değiştir
                </Button>
              </div>
            ) : (
              <Button
                type="button"
                onClick={() => fileInputRef.current?.click()}
                variant="default"
                className="w-full h-48 border-2 border-dashed bg-gray-50 hover:bg-gray-100 transition-colors"
              >
                <div className="flex flex-col items-center gap-3">
                  <Camera className="h-8 w-8" />
                  <span className="text-lg font-medium">Fotoğraf Çek</span>
                </div>
              </Button>
            )}
          </div>

          {/* Kaydet Butonu */}
          <Button type="submit" className="w-full py-6 text-lg font-semibold bg-green-600 hover:bg-green-700 text-white">
            Kaydet
          </Button>
        </form>
      </CardContent>
    </Card>
  );
}