// src/pages/dashboard/order/OrderList.jsx
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../components/ui/button';
import { Plus, ChevronDown, ChevronUp, Trash2 } from 'lucide-react';
import { toast } from 'react-hot-toast';
import ConfirmModal from '../../../components/ui/ConfirmModal';
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "../../../components/ui/pagination";

const OrderList = () => {
    const navigate = useNavigate();
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [expandedOrder, setExpandedOrder] = useState(null);
    const [deleteModal, setDeleteModal] = useState({ show: false, id: null });
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const itemsPerPage = 10;

    const API_URL = process.env.REACT_APP_API_URL;

    const fetchOrders = async (page) => {
        try {
            // sort=-date parametresini ekleyerek tarihe göre azalan sıralama istiyoruz
            const response = await fetch(
                `${API_URL}/api/order?page=${page}&limit=${itemsPerPage}&sort=-date,-orderNumber`, 
                {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                }
            );
            const data = await response.json();
            if (response.ok) {
                setOrders(data.items);
                setTotalPages(Math.ceil(data.total / itemsPerPage));
            } else {
                throw new Error(data.error);
            }
        } catch (error) {
            const errorMessage = 'Siparişler yüklenirken bir hata oluştu';
            toast.error(errorMessage);
            window.dispatchEvent(new CustomEvent('toast', { 
                detail: { message: errorMessage, type: 'error' }
            }));
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchOrders(currentPage);
    }, [currentPage]);

    const handleDelete = async () => {
        try {
            const response = await fetch(`${API_URL}/api/order/${deleteModal.id}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (response.ok) {
                const successMessage = 'Sipariş başarıyla silindi';
                toast.success(successMessage);
                window.dispatchEvent(new CustomEvent('toast', { 
                    detail: { message: successMessage, type: 'success' }
                }));
                fetchOrders(currentPage);
            } else {
                throw new Error('Sipariş silinirken bir hata oluştu');
            }
        } catch (error) {
            const errorMessage = error.message;
            toast.error(errorMessage);
            window.dispatchEvent(new CustomEvent('toast', { 
                detail: { message: errorMessage, type: 'error' }
            }));
        } finally {
            setDeleteModal({ show: false, id: null });
        }
    };

    const formatDate = (dateString) => {
        return new Date(dateString).toLocaleDateString('tr-TR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
    };

    const renderPaginationItems = () => {
        const items = [];
        for (let i = 1; i <= totalPages; i++) {
            items.push(
                <PaginationItem key={i}>
                    <PaginationLink
                        onClick={() => setCurrentPage(i)}
                        isActive={currentPage === i}
                    >
                        {i}
                    </PaginationLink>
                </PaginationItem>
            );
        }
        return items;
    };

    return (
        <>
            <div className="space-y-6">
                <div className="flex justify-between items-center">
                    <h2 className="text-2xl font-bold text-gray-800 dark:text-gray-200">Sipariş Listesi</h2>
                    <Button onClick={() => navigate('/dashboard/order/add')}>
                        <Plus className="h-4 w-4 mr-2" />
                        Yeni Sipariş
                    </Button>
                </div>

                {loading ? (
                    <div className="text-center">Yükleniyor...</div>
                ) : (
                    <div className="bg-white dark:bg-dark-bg-secondary rounded-lg shadow">
                        <div className="overflow-x-auto">
                            <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                                <thead>
                                    <tr>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                                            Sipariş No
                                        </th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                                            Tarih
                                        </th>
                                        <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                                            İşlemler
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
                                    {orders.map((order) => (
                                        <React.Fragment key={order._id}>
                                            <tr className="hover:bg-gray-50 dark:hover:bg-gray-800 cursor-pointer">
                                                <td 
                                                    className="px-6 py-4 whitespace-nowrap text-gray-900 dark:text-gray-300"
                                                    onClick={() => setExpandedOrder(expandedOrder === order._id ? null : order._id)}
                                                >
                                                    <div className="flex items-center">
                                                        {expandedOrder === order._id ? 
                                                            <ChevronUp className="h-4 w-4 mr-2" /> : 
                                                            <ChevronDown className="h-4 w-4 mr-2" />
                                                        }
                                                        {order.orderNumber}
                                                    </div>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-gray-900 dark:text-gray-300">
                                                    {formatDate(order.date)}
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-center">
                                                    <Button
                                                        variant="outline"
                                                        size="sm"
                                                        className="text-red-600 hover:text-red-700"
                                                        onClick={() => setDeleteModal({ show: true, id: order._id })}
                                                    >
                                                        <Trash2 className="h-4 w-4" />
                                                    </Button>
                                                </td>
                                            </tr>
                                            {expandedOrder === order._id && (
                                                <tr>
                                                    <td colSpan="4" className="px-6 py-4 bg-gray-50 dark:bg-gray-800">
                                                        <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                                                            <thead>
                                                                <tr>
                                                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400">
                                                                        İrsaliye No
                                                                    </th>
                                                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400">
                                                                        Depo
                                                                    </th>
                                                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400">
                                                                        Ürün
                                                                    </th>
                                                                    <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 dark:text-gray-400">
                                                                        Adet
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {order.items.map((item, index) => (
                                                                    <tr key={index}>
                                                                        <td className="px-6 py-2 text-sm text-gray-900 dark:text-gray-300">
                                                                            {item.invoiceNumber}
                                                                        </td>
                                                                        <td className="px-6 py-2 text-sm text-gray-900 dark:text-gray-300">
                                                                            {item.warehouse.name}
                                                                        </td>
                                                                        <td className="px-6 py-2 text-sm text-gray-900 dark:text-gray-300">
                                                                            {item.product.name}
                                                                        </td>
                                                                        <td className="px-6 py-2 text-sm text-gray-900 dark:text-gray-300 text-right">
                                                                            {item.quantity}
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                            )}
                                        </React.Fragment>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                )}

                {/* Pagination */}
                <Pagination>
                    <PaginationContent>
                        <PaginationItem>
                            <PaginationPrevious 
                                onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                                disabled={currentPage === 1}
                            />
                        </PaginationItem>
                        
                        {renderPaginationItems()}
                        
                        <PaginationItem>
                            <PaginationNext 
                                onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
                                disabled={currentPage === totalPages}
                            />
                        </PaginationItem>
                    </PaginationContent>
                </Pagination>
            </div>

            <ConfirmModal
                isOpen={deleteModal.show}
                onClose={() => setDeleteModal({ show: false, id: null })}
                onConfirm={handleDelete}
                title="Sipariş Silme"
                message="Bu siparişi silmek istediğinizden emin misiniz? Bu işlem geri alınamaz."
            />
        </>
    );
};

export default OrderList;