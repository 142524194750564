import React, { useState, useEffect } from 'react';
import { Input } from '../../../components/ui/input';
import { Table, TableHeader, TableBody, TableRow, TableCell, TableHead } from '../../../components/ui/table';
import { Card, CardHeader, CardTitle, CardContent } from '../../../components/ui/card';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "../../../components/ui/alert-dialog";
import { Button } from '../../../components/ui/button';
import toast from 'react-hot-toast';
import { Trash2, Download, Eye } from 'lucide-react';

export default function ListRecords() {
  const [records, setRecords] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedPlatform, setSelectedPlatform] = useState('');
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);

  useEffect(() => {
    fetchRecords();
  }, []);

  // fetchRecords fonksiyonunu güncelleyelim
  const fetchRecords = async () => {
    try {
      const response = await fetch(`/api/ecommerce-records`);
      if (response.ok) {
        const data = await response.json();
        const sortedData = data.sort((a, b) => new Date(b.date) - new Date(a.date));
        setRecords(sortedData);
      } else {
        toast.error('Kayıtlar yüklenirken bir hata oluştu');
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('Kayıtlar yüklenirken bir hata oluştu');
    }
  };

  // handleDelete fonksiyonunu güncelleyelim
  const handleDelete = async () => {
    try {
      const deleteToast = toast.loading('Kayıt siliniyor...');

      const response = await fetch(`/api/ecommerce-records/${selectedRecord._id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        }
      });

      if (response.ok) {
        toast.success('Kayıt başarıyla silindi', {
          id: deleteToast,
        });
        fetchRecords();
      } else {
        toast.error('Kayıt silinirken bir hata oluştu', {
          id: deleteToast,
        });
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('Kayıt silinirken bir hata oluştu');
    }
    setDeleteDialogOpen(false);
  };

  // handleDownload fonksiyonunu güncelleyelim
  const handleDownload = async (image, orderNumber) => {
    try {
      const downloadToast = toast.loading('Görüntü indiriliyor...');
  
      // Tam URL oluştur
      const fullUrl = image.startsWith('/') ? `${window.location.origin}${image}` : image;
  
      const response = await fetch(fullUrl);
      
      if (!response.ok) {
        throw new Error('Görüntü alınamadı');
      }
  
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `siparis-${orderNumber}.jpg`;
      a.target = '_blank'; // Yeni sekmede aç
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
  
      toast.success('Görüntü başarıyla indirildi', {
        id: downloadToast,
      });
    } catch (error) {
      console.error('Error:', error);
      toast.error('Görüntü indirilirken bir hata oluştu');
    }
  };

  // Platformları dinamik olarak al
  const platforms = [...new Set(records.map(record => record.platform))];

  const filteredRecords = records.filter(record => {
    const matchesSearch = record.orderNumber.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesPlatform = selectedPlatform === '' || record.platform === selectedPlatform;
    return matchesSearch && matchesPlatform;
  });

  return (
    <>
      <Card>
        <CardHeader>
          <CardTitle>E-Ticaret Kayıtları</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="space-y-4 mb-4">
              <div className="flex gap-4">
                <div className="flex-1">
                  <Input
                    placeholder="Sipariş numarası ara..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
                <select
                  className="px-3 py-2 rounded-md border border-input bg-background text-sm"
                  value={selectedPlatform}
                  onChange={(e) => setSelectedPlatform(e.target.value)}
                >
                  <option value="">Tüm Platformlar</option>
                  {platforms.map((platform) => (
                    <option key={platform} value={platform}>
                      {platform}
                    </option>
                  ))}
                </select>
              </div>
              {(searchTerm || selectedPlatform) && (
                <div className="flex items-center justify-between text-sm text-muted-foreground">
                  <span>
                    {filteredRecords.length} kayıt bulundu
                  </span>
                  <Button
                    variant="ghost"
                    size="sm"
                    onClick={() => {
                      setSearchTerm('');
                      setSelectedPlatform('');
                    }}
                  >
                    Filtreleri Temizle
                  </Button>
                </div>
              )}
            </div>
                     
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Platform</TableHead>
                <TableHead>Sipariş No</TableHead>
                <TableHead>Sipariş Tarihi</TableHead>
                <TableHead>Kayıt Tarihi</TableHead>
                <TableHead>Görüntü</TableHead>
                <TableHead>İşlemler</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {filteredRecords.map((record) => (
                <TableRow key={record._id}>
                  <TableCell>{record.platform}</TableCell>
                  <TableCell>{record.orderNumber}</TableCell>
                  <TableCell>
                    {new Date(record.date).toLocaleDateString('tr-TR', {
                      day: '2-digit',
                      month: '2-digit',
                      year: 'numeric',
                      hour: '2-digit',
                      minute: '2-digit'
                    })}
                  </TableCell>
                  <TableCell>
                    {new Date(record.createdAt).toLocaleDateString('tr-TR', {
                      day: '2-digit',
                      month: '2-digit',
                      year: 'numeric',
                      hour: '2-digit',
                      minute: '2-digit'
                    })}
                  </TableCell>
                  <TableCell>
                    <img 
                      src={record.image ? (
                        record.image.startsWith('/uploads/') 
                          ? record.image 
                          : `/uploads/${record.image}`
                      ) : ''}
                      alt={`Order ${record.orderNumber}`}
                      className="w-24 h-24 object-cover rounded cursor-pointer"
                      onClick={() => {
                        const fullUrl = record.image.startsWith('/uploads/') 
                          ? record.image 
                          : `/uploads/${record.image}`;
                        setPreviewImage(fullUrl);
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <div className="flex space-x-2">
                      <Button
                        variant="outline"
                        size="icon"
                        onClick={() => {
                          const fullUrl = record.image.startsWith('/uploads/') 
                            ? record.image 
                            : `/uploads/${record.image}`;
                          setPreviewImage(fullUrl);
                        }}
                      >
                        <Eye className="h-4 w-4" />
                      </Button>
                      <Button
                        variant="outline"
                        size="icon"
                        onClick={() => handleDownload(record.imageUrl, record.orderNumber)}
                      >
                        <Download className="h-4 w-4" />
                      </Button>
                      <Button
                        variant="destructive"
                        size="icon"
                        onClick={() => {
                          setSelectedRecord(record);
                          setDeleteDialogOpen(true);
                        }}
                      >
                        <Trash2 className="h-4 w-4" />
                      </Button>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>

      {/* Delete Confirmation Dialog */}
      <AlertDialog open={deleteDialogOpen} onOpenChange={setDeleteDialogOpen}>
        <AlertDialogContent className="bg-white">
          <AlertDialogHeader>
            <AlertDialogTitle>Emin misiniz?</AlertDialogTitle>
            <AlertDialogDescription>
              Bu işlem kalıcı olarak kaydı silecektir.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>İptal</AlertDialogCancel>
            <AlertDialogAction onClick={handleDelete}>Sil</AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      {/* Image Preview Dialog */}
      <AlertDialog open={!!previewImage} onOpenChange={() => setPreviewImage(null)}>
        <AlertDialogContent className="max-w-4xl bg-white p-0 max-h-[90vh] flex flex-col">
          {/* Sabit Header */}
          <div className="p-4 border-b flex justify-between items-center bg-white sticky top-0 z-10">
            <AlertDialogTitle>Görüntü Önizleme</AlertDialogTitle>
            <AlertDialogDescription className="sr-only">
              Sipariş görüntüsünün detaylı önizlemesi
            </AlertDialogDescription>
            <AlertDialogCancel className="border-0 hover:bg-gray-100">
              Kapat
            </AlertDialogCancel>
          </div>
          {/* Scrollable Content */}
          <div className="p-4 overflow-y-auto flex-1">
            <img 
              src={previewImage ? (
                previewImage.startsWith('/uploads/') 
                  ? previewImage 
                  : `/uploads/${previewImage}`
              ) : ''}
              alt="Preview"
              className="w-full h-auto rounded-lg"
            />
          </div>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}