// src/pages/dashboard/product/ProductAdd.jsx
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../components/ui/button';
import { Input } from '../../../components/ui/input';
import { Card, CardHeader, CardTitle, CardDescription, CardContent, CardFooter } from '../../../components/ui/card';
import { toast } from 'react-hot-toast';

const ProductAdd = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        name: '',
        code: '',
        barcode: '',
        serialNumber: ''
    });
    const [isLoading, setIsLoading] = useState(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const API_URL = process.env.REACT_APP_API_URL;

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            const response = await fetch(`${API_URL}/api/product`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify(formData)
            });

            const data = await response.json();

            if (response.ok) {
                const successMessage = 'Ürün başarıyla eklendi';
                toast.success(successMessage);
                window.dispatchEvent(new CustomEvent('toast', { 
                    detail: { message: successMessage, type: 'success' }
                }));
                navigate('/dashboard/product/list');
            } else {
                throw new Error(data.error);
            }
        } catch (error) {
            const errorMessage = error.message || 'Ürün eklenirken bir hata oluştu';
            toast.error(errorMessage);
            window.dispatchEvent(new CustomEvent('toast', { 
                detail: { message: errorMessage, type: 'error' }
            }));
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="max-w-2xl mx-auto">
            <Card>
                <CardHeader>
                    <CardTitle>Yeni Ürün Ekle</CardTitle>
                    <CardDescription>Ürün bilgilerini girerek yeni bir ürün oluşturun.</CardDescription>
                </CardHeader>

                <form onSubmit={handleSubmit}>
                    <CardContent className="space-y-4">
                        <div className="space-y-1">
                            <label className="text-sm font-medium">Ürün Adı</label>
                            <Input
                                name="name"
                                value={formData.name}
                                onChange={handleInputChange}
                                required
                                placeholder="Ürün adını girin"
                            />
                        </div>

                        <div className="space-y-1">
                            <label className="text-sm font-medium">Ürün Kodu</label>
                            <Input
                                name="code"
                                value={formData.code}
                                onChange={handleInputChange}
                                required
                                placeholder="Ürün kodunu girin"
                                className="uppercase"
                            />
                        </div>

                        <div className="space-y-1">
                            <label className="text-sm font-medium">Ürün Barkodu</label>
                            <Input
                                name="barcode"
                                value={formData.barcode}
                                onChange={handleInputChange}
                                required
                                placeholder="Ürün barkodunu girin"
                            />
                        </div>

                        <div className="space-y-1">
                            <label className="text-sm font-medium">Üretim Seri No</label>
                            <Input
                                name="serialNumber"
                                value={formData.serialNumber}
                                onChange={handleInputChange}
                                required
                                placeholder="Üretim seri numarasını girin"
                            />
                        </div>
                    </CardContent>

                    <CardFooter className="flex justify-end space-x-4">
                        <Button
                            type="button"
                            variant="outline"
                            onClick={() => navigate('/dashboard/product/list')}
                        >
                            İptal
                        </Button>
                        <Button
                            type="submit"
                            disabled={isLoading}
                        >
                            {isLoading ? 'Ekleniyor...' : 'Ürün Ekle'}
                        </Button>
                    </CardFooter>
                </form>
            </Card>
        </div>
    );
};

export default ProductAdd;