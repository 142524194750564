// components/ProtectedRoute.jsx
import { Navigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';

const ProtectedRoute = ({ children, permission }) => {
  const { isAuthenticated, user } = useAuth();

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  // Permission kontrolü
  if (permission && user.role !== permission) {
    return <Navigate to={user.role === 'admin' ? '/dashboard' : '/dashboard/e-commerce-records/list'} />;
  }

  // children bir fonksiyon ise, user prop'unu geçir
  if (typeof children === 'function') {
    return children({ user });
  }

  return children;
};

export default ProtectedRoute;