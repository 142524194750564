import { useState, useEffect, useRef } from 'react';

export const useLogWebSocket = () => {
  // Her log türü için boş array ile başla
  const [logs, setLogs] = useState({
    backend: [],
    frontend: [],
    mongodb: [],
    system: [] // Sistem mesajları için
  });

  const originalConsoleRef = useRef({
    log: console.log,
    error: console.error,
    warn: console.warn,
    info: console.info
  });

  useEffect(() => {
    let reconnectTimeout;
    let ws;

    const connect = () => {
      ws = new WebSocket('ws://bo.loopcorporate.com:5000/logs');

      ws.onopen = () => {
        originalConsoleRef.current.info('WebSocket bağlantısı kuruldu');
      };

      ws.onmessage = (event) => {
        try {
          const data = JSON.parse(event.data);
          
          setLogs(prev => {
            // Log türü geçerli mi kontrol et
            if (!prev.hasOwnProperty(data.type)) {
              console.warn(`Bilinmeyen log türü: ${data.type}`);
              return prev;
            }

            // Yeni log array'ini oluştur
            const newLogs = { ...prev };
            newLogs[data.type] = [
              data.data,
              ...(Array.isArray(prev[data.type]) ? prev[data.type] : [])
            ].slice(0, 100);

            return newLogs;
          });
        } catch (error) {
          originalConsoleRef.current.error('Log mesajı işlenirken hata:', error);
        }
      };

      // Console metodlarını override et
      console.log = (...args) => {
        if (ws?.readyState === WebSocket.OPEN) {
          try {
            ws.send(JSON.stringify({
              type: 'frontend',
              data: {
                level: 'info',
                message: args.map(arg => 
                  typeof arg === 'object' ? JSON.stringify(arg) : String(arg)
                ).join(' '),
                timestamp: new Date().toISOString()
              }
            }));
          } catch (error) {
            originalConsoleRef.current.error('Log gönderirken hata:', error);
          }
        }
        originalConsoleRef.current.log(...args);
      };

      // Diğer console metodları için benzer güvenlik kontrolleri
      console.error = (...args) => {
        if (ws?.readyState === WebSocket.OPEN) {
          try {
            ws.send(JSON.stringify({
              type: 'frontend',
              data: {
                level: 'error',
                message: args.map(arg => 
                  typeof arg === 'object' ? JSON.stringify(arg) : String(arg)
                ).join(' '),
                timestamp: new Date().toISOString()
              }
            }));
          } catch (error) {
            originalConsoleRef.current.error('Log gönderirken hata:', error);
          }
        }
        originalConsoleRef.current.error(...args);
      };

      console.warn = (...args) => {
        if (ws?.readyState === WebSocket.OPEN) {
          try {
            ws.send(JSON.stringify({
              type: 'frontend',
              data: {
                level: 'warn',
                message: args.map(arg => 
                  typeof arg === 'object' ? JSON.stringify(arg) : String(arg)
                ).join(' '),
                timestamp: new Date().toISOString()
              }
            }));
          } catch (error) {
            originalConsoleRef.current.error('Log gönderirken hata:', error);
          }
        }
        originalConsoleRef.current.warn(...args);
      };

      console.info = (...args) => {
        if (ws?.readyState === WebSocket.OPEN) {
          try {
            ws.send(JSON.stringify({
              type: 'frontend',
              data: {
                level: 'info',
                message: args.map(arg => 
                  typeof arg === 'object' ? JSON.stringify(arg) : String(arg)
                ).join(' '),
                timestamp: new Date().toISOString()
              }
            }));
          } catch (error) {
            originalConsoleRef.current.error('Log gönderirken hata:', error);
          }
        }
        originalConsoleRef.current.info(...args);
      };

      ws.onclose = () => {
        originalConsoleRef.current.info('WebSocket bağlantısı kapandı, yeniden bağlanılıyor...');
        reconnectTimeout = setTimeout(connect, 5000);
      };

      ws.onerror = (error) => {
        originalConsoleRef.current.error('WebSocket hatası:', error);
      };
    };

    connect();

    return () => {
      if (ws) {
        ws.close();
      }
      if (reconnectTimeout) {
        clearTimeout(reconnectTimeout);
      }
      console.log = originalConsoleRef.current.log;
      console.error = originalConsoleRef.current.error;
      console.warn = originalConsoleRef.current.warn;
      console.info = originalConsoleRef.current.info;
    };
  }, []);

  return logs;
};