import React, { useState, useEffect } from 'react';
import { Card } from '../../../components/ui/card';
import { Button } from '../../../components/ui/button';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../../../components/ui/select';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../../components/ui/table';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { Package, TrendingUp } from 'lucide-react';
import toast from 'react-hot-toast';

const MARKETPLACES = {
    all: { name: 'Tüm Marketplaceler', color: '#3b82f6' },
    trendyol: { name: 'Trendyol', color: '#f27a1a' },
    loop: { name: 'Loop', color: '#00a0dc' },
    hepsiburada: { name: 'Hepsiburada', color: '#ff6000' },
    n11: { name: 'N11', color: '#7c25f8' },
    amazon: { name: 'Amazon', color: '#ff9900' }
};

const SORT_OPTIONS = {
    quantity: { label: 'Satış Adedi', key: 'quantity' },
    revenue: { label: 'Toplam Gelir', key: 'revenue' }
};

const VIEW_TYPES = {
    ALL_YEARS: 'all_years',
    YEARLY: 'yearly',
    MONTHLY: 'monthly'
};

const ProductStats = () => {
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedMarketplace, setSelectedMarketplace] = useState('all');
    const [sortBy, setSortBy] = useState('quantity');
    const [viewType, setViewType] = useState(VIEW_TYPES.YEARLY);
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear().toString());
    const [selectedMonth, setSelectedMonth] = useState((new Date().getMonth() + 1).toString());
    const [availableYears, setAvailableYears] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [selectedProductName, setSelectedProductName] = useState(null);

    const API_URL = process.env.REACT_APP_API_URL;

    const fetchAvailableYears = async () => {
        try {
            const response = await fetch(`${API_URL}/api/parasut/available-years`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            
            if (!response.ok) throw new Error('Years fetch failed');
            
            const years = await response.json();
            setAvailableYears(years);
            
            if (years.length > 0 && !selectedYear) {
                setSelectedYear(years[0].toString());
            }
        } catch (error) {
            toast.error('Yıl bilgisi alınamadı');
        }
    };

    const fetchProducts = async () => {
        try {
            const marketplace = selectedMarketplace === 'all' ? '' : selectedMarketplace;
            let url = `${API_URL}/api/parasut/product-stats?marketplace=${marketplace}&sort=${sortBy}`;
            
            if (viewType === VIEW_TYPES.YEARLY) {
                url += `&year=${selectedYear}`;
            } else if (viewType === VIEW_TYPES.MONTHLY) {
                url += `&year=${selectedYear}&month=${selectedMonth}`;
            }
            
            const response = await fetch(url, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            
            if (!response.ok) throw new Error('Products fetch failed');

            const data = await response.json();
            setProducts(data);
        } catch (error) {
            console.error('Error:', error);
            toast.error('Ürün istatistikleri alınamadı');
        } finally {
            setLoading(false);
        }
    };

    const fetchProductDetails = async (productName) => {
        try {
            let url = `${API_URL}/api/parasut/product/${encodeURIComponent(productName)}`;
            
            const params = new URLSearchParams();
            
            if (selectedMarketplace !== 'all') {
                params.append('marketplace', selectedMarketplace);
            }

            if (viewType === VIEW_TYPES.YEARLY) {
                params.append('year', selectedYear);
            } else if (viewType === VIEW_TYPES.MONTHLY) {
                params.append('year', selectedYear);
                params.append('month', selectedMonth);
            }

            if (params.toString()) {
                url += `?${params.toString()}`;
            }

            const response = await fetch(url, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            
            if (!response.ok) throw new Error('Product details fetch failed');

            const data = await response.json();
            setSelectedProduct(data);
        } catch (error) {
            console.error('Error fetching product details:', error);
            toast.error('Ürün detayları alınamadı');
        }
    };

    const handleProductSelect = (productName) => {
        setSelectedProductName(productName);
        fetchProductDetails(productName);
    };

    const formatCurrency = (value) => {
        return `₺${value.toLocaleString('tr-TR', { minimumFractionDigits: 2 })}`;
    };

    const getTimeRangeText = () => {
        if (viewType === VIEW_TYPES.ALL_YEARS) return 'Tüm Zamanlar';
        if (viewType === VIEW_TYPES.YEARLY) return `${selectedYear} Yılı`;
        return `${selectedYear} - ${new Date(2024, parseInt(selectedMonth) - 1).toLocaleString('tr-TR', { month: 'long' })}`;
    };

    useEffect(() => {
        fetchAvailableYears();
    }, []);

    useEffect(() => {
        fetchProducts();
    }, [selectedMarketplace, sortBy, viewType, selectedYear, selectedMonth]);

    useEffect(() => {
        if (selectedProductName) {
            fetchProductDetails(selectedProductName);
        }
    }, [selectedYear, selectedMonth, viewType, selectedMarketplace]);

    return (
        <div className="space-y-6">
            <div className="flex justify-between items-center flex-wrap gap-4">
                <h2 className="text-2xl font-bold">Ürün İstatistikleri</h2>
                <div className="flex flex-wrap gap-4">
                    <Select value={viewType} onValueChange={setViewType}>
                        <SelectTrigger className="w-40">
                            <SelectValue placeholder="Görüntüleme" />
                        </SelectTrigger>
                        <SelectContent>
                            <SelectItem value={VIEW_TYPES.ALL_YEARS}>Tüm Yıllar</SelectItem>
                            <SelectItem value={VIEW_TYPES.YEARLY}>Yıllık</SelectItem>
                            <SelectItem value={VIEW_TYPES.MONTHLY}>Aylık</SelectItem>
                        </SelectContent>
                    </Select>

                    {viewType !== VIEW_TYPES.ALL_YEARS && (
                        <Select value={selectedYear} onValueChange={setSelectedYear}>
                            <SelectTrigger className="w-40">
                                <SelectValue placeholder="Yıl Seçin" />
                            </SelectTrigger>
                            <SelectContent>
                                {availableYears.map(year => (
                                    <SelectItem key={year} value={year.toString()}>
                                        {year} Yılı
                                    </SelectItem>
                                ))}
                            </SelectContent>
                        </Select>
                    )}

                    {viewType === VIEW_TYPES.MONTHLY && (
                        <Select value={selectedMonth} onValueChange={setSelectedMonth}>
                            <SelectTrigger className="w-40">
                                <SelectValue placeholder="Ay Seçin" />
                            </SelectTrigger>
                            <SelectContent>
                                {Array.from({ length: 12 }, (_, i) => i + 1).map(month => (
                                    <SelectItem key={month} value={month.toString()}>
                                        {new Date(2024, month - 1).toLocaleString('tr-TR', { month: 'long' })}
                                    </SelectItem>
                                ))}
                            </SelectContent>
                        </Select>
                    )}

                    <Select value={selectedMarketplace} onValueChange={setSelectedMarketplace}>
                        <SelectTrigger className="w-40">
                            <SelectValue placeholder="Marketplace" />
                        </SelectTrigger>
                        <SelectContent>
                            {Object.entries(MARKETPLACES).map(([key, { name }]) => (
                                <SelectItem key={key} value={key}>{name}</SelectItem>
                            ))}
                        </SelectContent>
                    </Select>

                    <Select value={sortBy} onValueChange={setSortBy}>
                        <SelectTrigger className="w-40">
                            <SelectValue placeholder="Sıralama" />
                        </SelectTrigger>
                        <SelectContent>
                            {Object.entries(SORT_OPTIONS).map(([key, { label }]) => (
                                <SelectItem key={key} value={key}>{label}</SelectItem>
                            ))}
                        </SelectContent>
                    </Select>
                </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                {!loading && (
                    <>
                        <Card className="p-6">
                            <div className="flex justify-between items-center">
                                <div>
                                    <p className="text-sm text-gray-500">{getTimeRangeText()} Toplam Satış Adedi</p>
                                    <h3 className="text-2xl font-bold mt-1">
                                        {products.reduce((sum, p) => sum + p.total_quantity, 0).toLocaleString()}
                                    </h3>
                                </div>
                                <Package className="w-8 h-8 text-blue-500" />
                            </div>
                        </Card>

                        <Card className="p-6">
                            <div className="flex justify-between items-center">
                                <div>
                                    <p className="text-sm text-gray-500">{getTimeRangeText()} Toplam Gelir</p>
                                    <h3 className="text-2xl font-bold mt-1">
                                        {formatCurrency(products.reduce((sum, p) => sum + p.total_revenue, 0))}
                                    </h3>
                                </div>
                                <TrendingUp className="w-8 h-8 text-green-500" />
                            </div>
                        </Card>

                        <Card className="p-6">
                            <div className="flex justify-between items-center">
                                <div>
                                    <p className="text-sm text-gray-500">{getTimeRangeText()} Ortalama Ürün Fiyatı</p>
                                    <h3 className="text-2xl font-bold mt-1">
                                        {formatCurrency(
                                            products.reduce((sum, p) => sum + p.total_revenue, 0) /
                                            products.reduce((sum, p) => sum + p.total_quantity, 0) || 0
                                        )}
                                    </h3>
                                </div>
                                <Package className="w-8 h-8 text-purple-500" />
                            </div>
                        </Card>
                    </>
                )}
            </div>

            <Card className="overflow-hidden">
                <Table>
                    <TableHeader>
                        <TableRow>
                            <TableHead>Ürün</TableHead>
                            <TableHead className="text-right">Satış Adedi</TableHead>
                            <TableHead className="text-right">Toplam Gelir</TableHead>
                            <TableHead className="text-right">Ortalama Fiyat</TableHead>
                            <TableHead className="text-right">Son Satış</TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {loading ? (
                            <TableRow>
                                <TableCell colSpan={5} className="text-center">Yükleniyor...</TableCell>
                            </TableRow>
                        ) : products.filter(product => product.total_quantity > 0).length === 0 ? (
                            <TableRow>
                                <TableCell colSpan={5} className="text-center text-gray-500">
                                    Bu dönemde satış yapılan ürün bulunmuyor
                                </TableCell>
                            </TableRow>
                        ) : products
                            .filter(product => product.total_quantity > 0)
                            .map((product) => (
                                <TableRow 
                                    key={product.name} 
                                    className="cursor-pointer hover:bg-gray-50" 
                                    onClick={() => handleProductSelect(product.name)}
                                >
                                    <TableCell>{product.name}</TableCell>
                                    <TableCell className="text-right">{product.total_quantity.toLocaleString()}</TableCell>
                                    <TableCell className="text-right">{formatCurrency(product.total_revenue)}</TableCell>
                                    <TableCell className="text-right">
                                        {formatCurrency(product.total_revenue / product.total_quantity)}
                                    </TableCell>
                                    <TableCell className="text-right">
                                        {new Date(product.last_sale_date).toLocaleDateString('tr-TR')}
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </Card>

            {selectedProduct && Object.values(selectedProduct.marketplaceStats).some(stats => stats.total_quantity > 0) ? (
                <Card className="p-6">
                    <h3 className="text-xl font-bold mb-4">{selectedProduct.product.name}</h3>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <div>
                            <h4 className="text-lg font-semibold mb-2">
                                Marketplace Dağılımı ({getTimeRangeText()})
                            </h4>
                            <div className="h-[300px]">
                                <ResponsiveContainer width="100%" height="100%">
                                    <BarChart data={Object.entries(selectedProduct.marketplaceStats)
                                        .filter(([_, stats]) => stats.total_quantity > 0)
                                        .map(([key, stats]) => ({
                                            name: MARKETPLACES[key]?.name || key,
                                            quantity: stats.total_quantity,
                                            revenue: stats.total_revenue,
                                            color: MARKETPLACES[key]?.color
                                        }))}>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="name" />
                                        <YAxis yAxisId="left" orientation="left" />
                                        <YAxis yAxisId="right" orientation="right" />
                                        <Tooltip />
                                        <Bar yAxisId="left" dataKey="quantity" fill="#8884d8" name="Satış Adedi" />
                                        <Bar yAxisId="right" dataKey="revenue" fill="#82ca9d" name="Gelir" />
                                    </BarChart>
                                </ResponsiveContainer>
                            </div>
                        </div>
                        
                        <div>
                            <h4 className="text-lg font-semibold mb-2">
                                Marketplace Bazında İstatistikler ({getTimeRangeText()})
                            </h4>
                            <div className="space-y-4">
                                {Object.entries(selectedProduct.marketplaceStats)
                                    .filter(([_, stats]) => stats.total_quantity > 0)
                                    .map(([key, stats]) => (
                                        <div key={key} className="p-4 bg-gray-50 rounded-lg">
                                            <div className="flex justify-between items-center mb-2">
                                                <h5 className="font-medium">{MARKETPLACES[key]?.name || key}</h5>
                                                <span className="text-sm text-gray-500">
                                                    {stats.total_quantity} adet
                                                </span>
                                            </div>
                                            <div className="flex justify-between items-center">
                                                <span className="text-gray-500">Toplam Gelir</span>
                                                <span className="font-medium">{formatCurrency(stats.total_revenue)}</span>
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </div>
                </Card>
            ) : selectedProduct ? (
                <Card className="p-6">
                    <div className="text-center text-gray-500">
                        {getTimeRangeText()} için "{selectedProduct.product.name}" ürününe ait satış verisi bulunmuyor
                    </div>
                </Card>
            ) : null}
        </div>
    );
};

export default ProductStats;