// src/pages/dashboard/DashboardPage.jsx
import React, { useState, useEffect } from 'react';
import { Card } from '../../../components/ui/card';
import { Button } from '../../../components/ui/button';
import { Tabs, TabsList, TabsTrigger, TabsContent } from '../../../components/ui/tabs';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../../../components/ui/select';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { ShoppingCart, TrendingUp, Calendar, RefreshCcw, AlertCircle } from 'lucide-react';
import toast from 'react-hot-toast';

const API_URL = process.env.REACT_APP_API_URL;

const MARKETPLACES = {
    total: {
        name: 'Toplam',
        color: '#2563eb'
    },
    trendyol: {
        name: 'Trendyol',
        searchTerm: 'www.trendyol.com',
        color: '#f27a1a'
    },
    loop: {
        name: 'Loop',
        searchTerm: 'loopcorporate.com',
        color: '#00a0dc'
    },
    hepsiburada: {
        name: 'Hepsiburada',
        searchTerm: 'www.hepsiburada.com',
        color: '#ff6000'
    },
    n11: {
        name: 'N11',
        searchTerm: 'www.n11.com',
        color: '#7c25f8'
    },
    amazon: {
        name: 'Amazon',
        searchTerm: 'www.amazon.com',
        color: '#ff9900'
    }
};

const StatCard = ({ title, value, icon: Icon, description }) => (
    <Card className="p-6">
        <div className="flex items-center">
            <div className="flex-1">
                <p className="text-sm font-medium text-gray-600 dark:text-gray-400">{title}</p>
                <h3 className="text-2xl font-bold mt-2">{value}</h3>
                {description && (
                    <p className="text-sm text-gray-500 mt-1">{description}</p>
                )}
            </div>
            <div className="bg-blue-50 p-3 rounded-lg dark:bg-blue-900">
                <Icon className="w-6 h-6 text-blue-500 dark:text-blue-300" />
            </div>
        </div>
    </Card>
);

const monthNames = {
    1: 'Ocak',
    2: 'Şubat',
    3: 'Mart',
    4: 'Nisan',
    5: 'Mayıs',
    6: 'Haziran',
    7: 'Temmuz',
    8: 'Ağustos',
    9: 'Eylül',
    10: 'Ekim',
    11: 'Kasım',
    12: 'Aralık'
};

const MarketplaceStats = ({ data, color }) => {
    if (!data) return null;

    const { yearlyStats, monthlyStats } = data;

    return (
        <div className="space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
                <StatCard
                    title="Bu Seneki Toplam Sipariş"
                    value={yearlyStats.total_orders?.toLocaleString() || 0}
                    icon={ShoppingCart}
                />
                <StatCard
                    title="Bu Ayki Siparişler"
                    value={monthlyStats.total_orders?.toLocaleString() || 0}
                    icon={Calendar}
                />
                <StatCard
                    title="Bu Seneki Toplam Kazanç"
                    value={`₺${yearlyStats.total_revenue?.toLocaleString('tr-TR', { minimumFractionDigits: 2 }) || 0}`}
                    icon={TrendingUp}
                />
                <StatCard
                    title="Bu Ayki Toplam Kazanç"
                    value={`₺${monthlyStats.total_revenue?.toLocaleString('tr-TR', { minimumFractionDigits: 2 }) || 0}`}
                    icon={TrendingUp}
                />
            </div>
            

            <Card className="p-6">
                <h3 className="text-lg font-semibold mb-4">Aylık Satış Grafiği</h3>
                <div className="h-[400px]">
                    <ResponsiveContainer width="100%" height="100%">
                        <LineChart
                            data={data.monthlyData}
                            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis 
                                dataKey="_id.month"
                                tickFormatter={(month) => monthNames[month]}
                            />
                            <YAxis yAxisId="left" orientation="left" stroke={color} />
                            <YAxis yAxisId="right" orientation="right" stroke={`${color}88`} />
                            <Tooltip />
                            <Line
                                yAxisId="left"
                                type="monotone"
                                dataKey="orders"
                                name="Sipariş Sayısı"
                                stroke={color}
                                strokeWidth={2}
                            />
                            <Line
                                yAxisId="right"
                                type="monotone"
                                dataKey="revenue"
                                name="Gelir (₺)"
                                stroke={`${color}88`}
                                strokeWidth={2}
                            />
                        </LineChart>
                    </ResponsiveContainer>
                </div>
            </Card>
        </div>
    );
};

const DashboardPage = () => {
    const [marketplaceData, setMarketplaceData] = useState({});
    const [loading, setLoading] = useState(true);
    const [syncing, setSyncing] = useState(false);
    const [availableYears, setAvailableYears] = useState([]);
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear().toString());

    const handleSync = async () => {
        setSyncing(true);
        try {
            const response = await fetch(`${API_URL}/api/parasut/sync`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                throw new Error('Sync failed');
            }

            const data = await response.json();
            
            // Toast ve notification event'ini tetikle
            const message = data.message || 'Senkronizasyon başarıyla tamamlandı';
            toast.success(message);
            window.dispatchEvent(new CustomEvent('toast', { 
                detail: { 
                    message: message, 
                    type: 'success' 
                }
            }));

            // Senkronizasyon başarılı olduktan sonra verileri yeniden yükle
            fetchMarketplaceStats();

        } catch (error) {
            const errorMessage = 'Senkronizasyon sırasında hata oluştu';
            toast.error(errorMessage);
            window.dispatchEvent(new CustomEvent('toast', { 
                detail: { 
                    message: errorMessage, 
                    type: 'error' 
                }
            }));
        } finally {
            setSyncing(false);
        }
    };

    const fetchAvailableYears = async () => {
        try {
            const response = await fetch(`${API_URL}/api/parasut/available-years`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            
            if (!response.ok) throw new Error('Years fetch failed');
            
            const years = await response.json();
            setAvailableYears(years);
            
            if (years.length > 0 && !selectedYear) {
                setSelectedYear(years[0].toString());
            }
        } catch (error) {
            toast.error('Yıl bilgisi alınamadı');
        }
    };

    const fetchMarketplaceStats = async () => {
        try {
            console.log('Starting API request...');
            const response = await fetch(`${API_URL}/api/parasut/marketplace-stats?year=${selectedYear}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                }
            });
    
            if (!response.ok) {
                const errorData = await response.json();
                console.error('API error details:', errorData);
                throw new Error(errorData.error || 'Stats fetch failed');
            }
    
            const data = await response.json();
            console.log('Received data:', data);
            setMarketplaceData(data);
        } catch (error) {
            console.error('Error fetching stats:', error);
            toast.error('İstatistikler alınamadı');
        } finally {
            setLoading(false);
        }
    };

    // ... handleSync fonksiyonu aynı kalacak ...

    useEffect(() => {
        fetchAvailableYears();
    }, []);

    useEffect(() => {
        if (selectedYear) {
            fetchMarketplaceStats();
        }
    }, [selectedYear]);

    if (loading) {
        return <div>Yükleniyor...</div>;
    }

    return (
        <div className="space-y-6">
            <div className="flex justify-between items-center">
                <div className="flex items-center gap-4">
                    <h2 className="text-2xl font-bold">Marketplace İstatistikleri</h2>
                    <Select value={selectedYear} onValueChange={setSelectedYear}>
                        <SelectTrigger className="w-40">
                            <SelectValue placeholder="Yıl Seçin" />
                        </SelectTrigger>
                        <SelectContent>
                            {availableYears.map(year => (
                                <SelectItem key={year} value={year.toString()}>
                                    {year} Yılı
                                </SelectItem>
                            ))}
                        </SelectContent>
                    </Select>
                </div>
                <Button 
                    onClick={handleSync} 
                    disabled={syncing}
                    className="flex items-center gap-2"
                >
                    <RefreshCcw className={`w-4 h-4 ${syncing ? 'animate-spin' : ''}`} />
                    {syncing ? 'Güncelleniyor...' : 'Verileri Güncelle'}
                </Button>
            </div>

            <Tabs defaultValue="total" className="w-full">
                <TabsList className="w-full justify-start">
                    {Object.entries(MARKETPLACES)
                        .sort(([keyA], [keyB]) => keyA === 'total' ? -1 : keyB === 'total' ? 1 : 0)
                        .map(([key, { name }]) => (
                            <TabsTrigger key={key} value={key}>
                                {name}
                            </TabsTrigger>
                        ))}
                </TabsList>

                {Object.entries(MARKETPLACES).map(([key, { name, color }]) => (
                    <TabsContent key={key} value={key}>
                        <h3 className="text-xl font-semibold mb-6">{name} İstatistikleri</h3>
                        <MarketplaceStats 
                            data={marketplaceData[key]} 
                            color={color}
                        />
                    </TabsContent>
                ))}
            </Tabs>
        </div>
    );
};

export default DashboardPage;