import React, { createContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      // Token'ı doğrula ve kullanıcı bilgilerini al
      fetch(`${API_URL}/api/auth/verify`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        credentials: 'include'
      })
      .then(res => res.json())
      .then(data => {
        if (data.user) {
          setUser(data.user);
          setIsAuthenticated(true);
        } else {
          localStorage.removeItem('token');
          setIsAuthenticated(false);
        }
      })
      .catch(() => {
        localStorage.removeItem('token');
        setIsAuthenticated(false);
      })
      .finally(() => {
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, [API_URL]);

  const login = async (credentials) => {
    try {
      console.log('API URL:', API_URL);
      const response = await fetch(`${API_URL}/api/auth/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        mode: 'cors',
        body: JSON.stringify({
          username: credentials.username,
          password: credentials.password
        })
      });

      const data = await response.json();

      if (response.ok) {
        if (data.requireSetup) {
          // 2FA kurulumu gerekiyor
          localStorage.setItem('setupToken', data.token);
          navigate('/setup-2fa');
          return { success: true };
        } else if (data.require2FA) {
          // 2FA doğrulaması gerekiyor
          localStorage.setItem('tempToken', data.tempToken);
          return { success: true, require2FA: true };
        } else {
          // Normal giriş başarılı
          localStorage.setItem('token', data.token);
          setUser(data.user);
          setIsAuthenticated(true);
          if (data.user.role === 'admin') {
            navigate('/dashboard');
          } else {
            navigate('/dashboard/e-commerce-records/list');
          }
          return { success: true };
        }
      } else {
        throw new Error(data.error || 'Giriş başarısız');
      }
    } catch (error) {
      console.error('Login error:', error);
      return { success: false, error: error.message };
    }
  };

  const verify2FA = async (otpCode) => {
    try {
      const response = await fetch(`${API_URL}/api/auth/verify-2fa`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('tempToken')}`
        },
        credentials: 'include',
        body: JSON.stringify({
          token: otpCode
        })
      });

      const data = await response.json();

      if (response.ok) {
        localStorage.setItem('token', data.token);
        localStorage.removeItem('tempToken');
        setUser(data.user);
        setIsAuthenticated(true);
        navigate('/dashboard');
        return { success: true };
      } else {
        throw new Error(data.error || 'Doğrulama başarısız');
      }
    } catch (error) {
      console.error('2FA verification error:', error);
      return { success: false, error: error.message };
    }
  };

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('tempToken');
    localStorage.removeItem('setupToken');
    setUser(null);
    setIsAuthenticated(false);
    navigate('/login');
  };

  if (loading) {
    return <div>Yükleniyor...</div>;
  }

  return (
    <AuthContext.Provider value={{ 
      user, 
      isAuthenticated, 
      login, 
      logout,
      verify2FA 
    }}>
      {children}
    </AuthContext.Provider>
  );
};