import React, { useState } from 'react';
import { useLogWebSocket } from '../../../hooks/useLogWebSocket';
import { Card, CardHeader, CardTitle, CardContent } from '../../../components/ui/card';
import { Input } from '../../../components/ui/input';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../../../components/ui/select';
import { Badge } from '../../../components/ui/badge';
import { ScrollArea } from '../../../components/ui/scroll-area';

const LogsPage = () => {
  const logs = useLogWebSocket();
  const [filters, setFilters] = useState({
    search: '',
    level: 'all'
  });

  const getLevelColor = (level) => {
    switch (level?.toLowerCase()) {
      case 'error': return 'bg-destructive text-destructive-foreground';
      case 'warn': return 'bg-warning text-warning-foreground';
      case 'info': return 'bg-info text-info-foreground';
      case 'debug': return 'bg-muted text-muted-foreground';
      default: return 'bg-secondary text-secondary-foreground';
    }
  };

  const filterLogs = (logArray) => {
    return logArray.filter(log => {
      if (filters.search && !log.message?.toLowerCase().includes(filters.search.toLowerCase())) {
        return false;
      }
      if (filters.level !== 'all' && log.level !== filters.level) {
        return false;
      }
      return true;
    });
  };

  const formatDate = (timestamp) => {
    return new Date(timestamp).toLocaleString('tr-TR', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    });
  };

  const LogSection = ({ title, logs, type }) => (
    <Card className="col-span-1">
      <CardHeader>
        <CardTitle className="flex items-center justify-between">
          <span>{title}</span>
          <Badge variant="outline" className="ml-2">
            {logs.length}
          </Badge>
        </CardTitle>
      </CardHeader>
      <CardContent>
        <ScrollArea className="h-[500px] w-full">
          <div className="space-y-2">
            {filterLogs(logs).map((log, index) => (
              <div
                key={`${type}-${index}`}
                className="rounded-lg border p-3 text-sm"
              >
                <div className="flex items-center gap-2 mb-1">
                  <Badge className={getLevelColor(log.level)}>
                    {log.level}
                  </Badge>
                  <span className="text-xs text-muted-foreground">
                    {formatDate(log.timestamp)}
                  </span>
                </div>
                <pre className="whitespace-pre-wrap font-mono text-xs">
                  {log.message}
                </pre>
                {log.error && (
                  <div className="mt-2 text-xs text-destructive">
                    <div>Error: {log.error}</div>
                    {log.stack && (
                      <pre className="mt-1 whitespace-pre-wrap text-[10px] text-muted-foreground">
                        {log.stack}
                      </pre>
                    )}
                  </div>
                )}
              </div>
            ))}
          </div>
        </ScrollArea>
      </CardContent>
    </Card>
  );

  return (
    <div className="space-y-6 p-6">
      <div className="flex justify-between items-center">
        <h2 className="text-3xl font-bold tracking-tight">Sistem Logları</h2>
        <div className="flex items-center gap-4">
          <Input
            placeholder="Log ara..."
            value={filters.search}
            onChange={(e) => setFilters(prev => ({ ...prev, search: e.target.value }))}
            className="w-64"
          />
          <Select
            value={filters.level}
            onValueChange={(value) => setFilters(prev => ({ ...prev, level: value }))}
          >
            <SelectTrigger className="w-32">
              <SelectValue placeholder="Seviye" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="all">Tümü</SelectItem>
              <SelectItem value="error">Error</SelectItem>
              <SelectItem value="warn">Warning</SelectItem>
              <SelectItem value="info">Info</SelectItem>
              <SelectItem value="debug">Debug</SelectItem>
            </SelectContent>
          </Select>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        <LogSection 
          title="Backend Logları" 
          logs={logs.backend} 
          type="backend" 
        />
        <LogSection 
          title="Frontend Logları" 
          logs={logs.frontend} 
          type="frontend" 
        />
        <LogSection 
          title="MongoDB Logları" 
          logs={logs.mongodb} 
          type="mongodb" 
        />
      </div>
    </div>
  );
};

export default LogsPage;