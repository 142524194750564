// src/pages/dashboard/order/OrderCreate.jsx
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../components/ui/button';
import { Input } from '../../../components/ui/input';
import { Card, CardHeader, CardTitle, CardDescription, CardContent, CardFooter } from '../../../components/ui/card';
import DatePicker from '../../../components/ui/DatePicker';
import ComboBox from '../../../components/ui/ComboBox';
import { Plus, Trash2 } from 'lucide-react';
import { toast } from 'react-hot-toast';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

const OrderCreate = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [warehouses, setWarehouses] = useState([]);
    const [products, setProducts] = useState([]);

    // Istanbul timezone'una göre bugünün tarihi
    const getIstanbulDate = () => {
        const now = new Date();
        const istanbulOffset = 3; // UTC+3
        const utc = now.getTime() + (now.getTimezoneOffset() * 60000);
        const istanbulDate = new Date(utc + (3600000 * istanbulOffset));
        const formattedDate = istanbulDate.toLocaleDateString('tr-TR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        }).split('.').join('/');
        return formattedDate;
    };

    const initialOrderLine = {
        invoiceNumber: '',
        date: getIstanbulDate(),
        warehouse: null,
        product: null,
        quantity: ''
    };

    const [orderLines, setOrderLines] = useState([initialOrderLine]);

    const API_URL = process.env.REACT_APP_API_URL;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [warehousesRes, productsRes] = await Promise.all([
                    fetch(`${API_URL}/api/warehouse?limit=100`, {
                        headers: {
                            'Authorization': `Bearer ${localStorage.getItem('token')}`
                        }
                    }),
                    fetch(`${API_URL}/api/product?limit=100`, {
                        headers: {
                            'Authorization': `Bearer ${localStorage.getItem('token')}`
                        }
                    })
                ]);
    
                const warehousesData = await warehousesRes.json();
                const productsData = await productsRes.json();
    
                setWarehouses(warehousesData.items || []);
                setProducts(productsData.items || []);
                
            } catch (error) {
                const message = 'Veriler yüklenirken bir hata oluştu';
                toast.error(message);
                window.dispatchEvent(new CustomEvent('toast', { 
                    detail: { message, type: 'error' }
                }));
            }
        };
    
        fetchData();
    }, []);

    const searchWarehouses = async (query) => {
        try {
            // warehouses state'inden arama yap
            return warehouses.filter(warehouse => 
                warehouse.name.toLowerCase().includes(query.toLowerCase()) ||
                warehouse.code.toLowerCase().includes(query.toLowerCase())
            );
        } catch (error) {
            console.error('Depo arama hatası:', error);
            return [];
        }
    };

    const searchProducts = async (query) => {
        try {
            // products state'inden arama yap
            return products.filter(product => 
                product.name.toLowerCase().includes(query.toLowerCase()) ||
                product.code.toLowerCase().includes(query.toLowerCase())
            );
        } catch (error) {
            console.error('Ürün arama hatası:', error);
            return [];
        }
    };

    const addNewLine = () => {
        setOrderLines([...orderLines, {...initialOrderLine}]);
    };

    const removeLine = (index) => {
        setOrderLines(lines => lines.filter((_, i) => i !== index));
    };

    const updateLine = (index, field, value) => {
        setOrderLines(lines => 
            lines.map((line, i) => 
                i === index ? { ...line, [field]: value } : line
            )
        );
    };

    // Excel export fonksiyonu

    const handleExcelExport = async (orderLines, orderNumber) => {
        try {
            const response = await fetch(`${API_URL}/api/export-excel`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify({ orderLines, orderNumber })
            });
    
            if (!response.ok) {
                throw new Error('Excel oluşturma hatası');
            }
    
            // Blob olarak al
            const blob = await response.blob();
            
            // Dosyayı indir
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = `Bill-${orderNumber}.xls`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
    
            return true;
        } catch (error) {
            const message = 'Excel dosyası indirilirken bir hata oluştu';
            toast.error(message);
            window.dispatchEvent(new CustomEvent('toast', { 
                detail: { message, type: 'error' }
            }));
            return false;
        }
    };
 
// OrderCreate.jsx içinde handleSubmit fonksiyonunu güncelle
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            const orderItems = orderLines.map(line => ({
                invoiceNumber: line.invoiceNumber,
                warehouse: line.warehouse._id,
                product: line.product._id,
                quantity: parseInt(line.quantity)
            }));

            const response = await fetch(`${API_URL}/api/order`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify({
                    date: orderLines[0].date,
                    items: orderItems
                })
            });

            const data = await response.json();

            // handleSubmit fonksiyonunda Excel indirme kısmı
            if (response.ok) {
                // Excel export işlemi
                const excelResponse = await fetch(`${API_URL}/api/export-excel`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    },
                    body: JSON.stringify({
                        orderLines: orderLines,
                        orderNumber: data.orderNumber || data.order?.orderNumber
                    })
                });

                if (excelResponse.ok) {
                    const blob = await excelResponse.blob();
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = url;
                    link.download = `Bill-${data.orderNumber || data.order?.orderNumber}.xls`; // .xlsx yerine .xls
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    window.URL.revokeObjectURL(url);
                            
                    const successMessage = 'Sipariş başarıyla oluşturuldu ve Excel indirildi';
                    toast.success(successMessage);
                    window.dispatchEvent(new CustomEvent('toast', { 
                        detail: { message: successMessage, type: 'success' }
                    }));
                } else {
                    const partialSuccessMessage = 'Sipariş başarıyla oluşturuldu fakat Excel indirilemedi';
                    toast.success(partialSuccessMessage);
                    window.dispatchEvent(new CustomEvent('toast', { 
                        detail: { message: partialSuccessMessage, type: 'warning' }
                    }));
                }
                        
                navigate('/dashboard/order/list');
            } else {
                throw new Error(data.error);
            }
        } catch (error) {
            const errorMessage = error.message || 'Sipariş oluşturulurken bir hata oluştu';
            toast.error(errorMessage);
            window.dispatchEvent(new CustomEvent('toast', { 
                detail: { message: errorMessage, type: 'error' }
            }));
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="max-w-7xl mx-auto">
            <Card>
                <CardHeader>
                    <CardTitle>Yeni Sipariş Oluştur</CardTitle>
                    <CardDescription>Sipariş bilgilerini girerek yeni bir sipariş oluşturun.</CardDescription>
                </CardHeader>

                <form onSubmit={handleSubmit}>
                    <CardContent className="space-y-4">
                        {orderLines.map((line, index) => (
                            <div key={index} className="flex items-center gap-4 p-4 border rounded-lg bg-gray-50 dark:bg-gray-800">
                                <Input
                                    placeholder="İrsaliye No"
                                    value={line.invoiceNumber}
                                    onChange={(e) => updateLine(index, 'invoiceNumber', e.target.value)}
                                    className="w-32"
                                    required
                                />
                                
                                <DatePicker
                                    value={line.date}
                                    onChange={(date) => updateLine(index, 'date', date)}
                                    className="w-40"
                                />
                                
                                <div className="flex-1">
                                    <ComboBox
                                        placeholder="Depo Seç"
                                        onSearch={searchWarehouses}
                                        onSelect={(warehouse) => updateLine(index, 'warehouse', warehouse)}
                                        value={line.warehouse}
                                        items={warehouses}
                                        displayKey="name"
                                    />
                                </div>
                                
                                <div className="flex-1">
                                    <ComboBox
                                        placeholder="Ürün Seç"
                                        onSearch={searchProducts}
                                        onSelect={(product) => updateLine(index, 'product', product)}
                                        value={line.product}
                                        items={products}
                                        displayKey="name"
                                    />
                                </div>
                                
                                <Input
                                    type="number"
                                    placeholder="Adet"
                                    value={line.quantity}
                                    onChange={(e) => updateLine(index, 'quantity', e.target.value)}
                                    className="w-24"
                                    min="1"
                                    required
                                />

                                {orderLines.length > 1 && (
                                    <Button
                                        type="button"
                                        variant="outline"
                                        size="icon"
                                        onClick={() => removeLine(index)}
                                    >
                                        <Trash2 className="h-4 w-4 text-red-500" />
                                    </Button>
                                )}
                            </div>
                        ))}

                        <Button
                            type="button"
                            variant="outline"
                            onClick={addNewLine}
                            className="w-full"
                        >
                            <Plus className="h-4 w-4 mr-2" />
                            Yeni Satır Ekle
                        </Button>
                    </CardContent>

                    <CardFooter className="flex justify-end space-x-4">
                        <Button
                            type="button"
                            variant="outline"
                            onClick={() => exportToExcel(orderLines)} // Test butonu
                        >
                            Excel Test
                        </Button>
                        <Button
                            type="button"
                            variant="outline"
                            onClick={() => navigate('/dashboard/order/list')}
                        >
                            İptal
                        </Button>
                        <Button
                            type="submit"
                            disabled={isLoading}
                        >
                            {isLoading ? 'Oluşturuluyor...' : 'Sipariş Oluştur'}
                        </Button>
                    </CardFooter>
                </form>
            </Card>
        </div>
    );
};

export default OrderCreate;