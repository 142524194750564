import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '../../components/ui/card';
import { Package, Warehouse, ShoppingCart, TrendingUp } from 'lucide-react';

const API_URL = process.env.REACT_APP_API_URL;

const StatCard = ({ title, value, icon: Icon, description, isLoading }) => (
  <Card>
    <CardContent className="p-6">
      <div className="flex items-center justify-between space-x-4">
        <div>
          <p className="text-sm font-medium text-muted-foreground">{title}</p>
          <h2 className="text-2xl font-bold">
            {isLoading ? "-" : value.toLocaleString('tr-TR')}
          </h2>
          {description && (
            <p className="text-xs text-muted-foreground mt-1">{description}</p>
          )}
        </div>
        <div className="p-2 bg-primary/10 rounded-full">
          <Icon className="w-5 h-5 text-primary" />
        </div>
      </div>
    </CardContent>
  </Card>
);

const DashboardPage = () => {
  const [stats, setStats] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const response = await fetch(`${API_URL}/api/dashboard/stats`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });

        if (response.ok) {
          const data = await response.json();
          setStats(data);
        } else {
          console.error('Error fetching stats:', await response.text());
        }
      } catch (error) {
        console.error('Error:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchStats();
  }, []);

  return (
    <div className="space-y-6">
      <div>
        <h2 className="text-3xl font-bold tracking-tight">Dashboard</h2>
        <p className="text-muted-foreground">
          Sistemin genel durumuna genel bakış
        </p>
      </div>

      <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-4">
        <StatCard
          title="Toplam Ürün"
          value={stats?.productCount || 0}
          icon={Package}
          description="Sistemdeki toplam ürün sayısı"
          isLoading={isLoading}
        />
        <StatCard
          title="Toplam Depo"
          value={stats?.warehouseCount || 0}
          icon={Warehouse}
          description="Aktif depo sayısı"
          isLoading={isLoading}
        />
        <StatCard
          title="Toplam Sipariş"
          value={stats?.totalOrders || 0}
          icon={ShoppingCart}
          description="Tüm zamanların sipariş sayısı"
          isLoading={isLoading}
        />
        <StatCard
          title="Aylık Sipariş"
          value={stats?.monthlyOrders || 0}
          icon={TrendingUp}
          description="Bu ayki toplam sipariş"
          isLoading={isLoading}
        />
      </div>

      <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
        <Card className="col-span-2">
          <CardHeader>
            <CardTitle>Satış Grafiği</CardTitle>
          </CardHeader>
          <CardContent>
            {/* Grafik verisi hazır, istediğinizde ekleyebiliriz */}
            <div className="h-[300px] flex items-center justify-center text-muted-foreground">
              {stats?.monthlyData ? 'Grafik verisi hazır' : 'Veri yükleniyor...'}
            </div>
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle>Bu Ayki İstatistikler</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="space-y-4">
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <p className="text-sm font-medium text-muted-foreground">Toplam Sipariş</p>
                  <p className="text-xl font-bold">{stats?.monthlyOrders || 0}</p>
                </div>
                <div>
                  <p className="text-sm font-medium text-muted-foreground">Yıllık Toplam</p>
                  <p className="text-xl font-bold">
                    {stats?.monthlyData?.reduce((acc, curr) => acc + curr.count, 0) || 0}
                  </p>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default DashboardPage;