// src/pages/dashboard/product/ProductEdit.jsx
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '../../../components/ui/button';
import { Input } from '../../../components/ui/input';
import { toast } from 'react-hot-toast';

const ProductEdit = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [formData, setFormData] = useState({
    name: '',
    code: '',
    barcode: '',
    serialNumber: ''
  });
  const [isLoading, setIsLoading] = useState(false);

  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await fetch(`${API_URL}/api/product/${id}`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
        const data = await response.json();
        
        if (response.ok) {
          setFormData({
            name: data.name,
            code: data.code,
            barcode: data.barcode,
            serialNumber: data.serialNumber
          });
        } else {
          throw new Error(data.error);
        }
      } catch (error) {
        const errorMessage = 'Ürün bilgileri yüklenirken bir hata oluştu';
        toast.error(errorMessage);
        window.dispatchEvent(new CustomEvent('toast', { 
          detail: { message: errorMessage, type: 'error' }
        }));
        navigate('/dashboard/product/list');
      }
    };

    fetchProduct();
  }, [id, navigate]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await fetch(`${API_URL}/api/product/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify(formData)
      });

      const data = await response.json();

      if (response.ok) {
        const successMessage = 'Ürün başarıyla güncellendi';
        toast.success(successMessage);
        window.dispatchEvent(new CustomEvent('toast', { 
          detail: { message: successMessage, type: 'success' }
        }));
        navigate('/dashboard/product/list');
      } else {
        throw new Error(data.error);
      }
    } catch (error) {
      const errorMessage = error.message || 'Güncelleme sırasında bir hata oluştu';
      toast.error(errorMessage);
      window.dispatchEvent(new CustomEvent('toast', { 
        detail: { message: errorMessage, type: 'error' }
      }));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="max-w-2xl mx-auto">
      <h2 className="text-2xl font-bold mb-6 text-gray-800 dark:text-gray-200">
        Ürün Düzenle
      </h2>
      
      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
              Ürün Adı
            </label>
            <Input
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              required
              placeholder="Ürün adını girin"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
              Ürün Kodu
            </label>
            <Input
              name="code"
              value={formData.code}
              onChange={handleInputChange}
              required
              placeholder="Ürün kodunu girin"
              className="uppercase"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
              Ürün Barkodu
            </label>
            <Input
              name="barcode"
              value={formData.barcode}
              onChange={handleInputChange}
              required
              placeholder="Ürün barkodunu girin"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
              Üretim Seri No
            </label>
            <Input
              name="serialNumber"
              value={formData.serialNumber}
              onChange={handleInputChange}
              required
              placeholder="Üretim seri numarasını girin"
            />
          </div>
        </div>

        <div className="flex justify-end space-x-4">
          <Button
            type="button"
            variant="outline"
            onClick={() => navigate('/dashboard/product/list')}
          >
            İptal
          </Button>
          <Button
            type="submit"
            disabled={isLoading}
          >
            {isLoading ? 'Güncelleniyor...' : 'Güncelle'}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default ProductEdit;